import React, { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import DashboardLayout from '../../layout/Layout';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { updateTemplate } from '../../../store/actions/emailTemplateAction';
import { getTemplates } from '../../../store/actions/emailTemplateAction';


const UpdateMailBox = () => {

    const { templates, auth:{ access_token } } = useSelector(
        state => ({
            auth: state.auth,
            templates: state.templates.templates
        })
    );

    let { id, description } = useParams();
    const dispatch = useDispatch();

    const emailEditorRef = useRef(null);
    const [emailTemplate, setEmailTemplate] = useState({})

    useEffect(() => {
        if(templates === null){
          dispatch(getTemplates(access_token))    
        }else{
            for(let index in templates){
                if(parseInt(id) === parseInt(templates[index].id)){
                    setEmailTemplate(JSON.parse(templates[index].json))
                }
            }
        }

    },[dispatch, access_token, templates, id])

    const exportHtml = (e) => {
        e.preventDefault()
        
        emailEditorRef.current.editor.exportHtml((data) => {
            const { html } = data;
            const jsondata = JSON.stringify(data.design);
            //console.log('HTML Template: ', jsondata)
            saveTemplate(html, jsondata)
        });

    };    

    const saveTemplate = (html, jsondata) => {
        const blob = new File([html], description+'.html', {type : 'text/html;charset=utf-8'});
        dispatch(updateTemplate(access_token, id, blob, jsondata));
    }

    const loadTemplate = () => {
        emailEditorRef.current.editor.loadDesign(emailTemplate);
    }

    return(
        <DashboardLayout>
            <Box display="flex" justifyContent="space-around">
                
                <Box mb={2}>
                    <Button variant="contained" color="primary" onClick={e => loadTemplate(e)}>Télécharger le template</Button>
                </Box>
                <Box mb={2}>
                    <Button variant="contained" color="secondary" onClick={e => exportHtml(e)}>Garder le template</Button>
                </Box>
            </Box>
            <Box>
                <EmailEditor
                    ref={emailEditorRef}                        
                    minHeight={'625px'}
                />
            </Box>
        </DashboardLayout>
  );
}

export default UpdateMailBox;