import axios from 'axios'
import { setAlert } from './alertAction'
import { GET_REQUEST, GET_A_REQUEST, GET_FILTER_PRO, PROCESSING, GET_RESERVATIONS_BY_PRO } from './typesAction'
import config from '../../config.json'
import constant from '../../constant.json'
import { getIdAmPmProSchedule } from '../../components/share/librery/librery'
import { getTaskActive } from './taskAction'

export const cleanReservation = () => async dispatch => {
    dispatch({
        type: PROCESSING
    })
}

export const getRequests = (token) => async dispatch => {
    try{
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        await axios.get(`${config.API_SERVER}/api/v1/admin/reservation/all`, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch({
                    type: GET_REQUEST,
                    payload: res.data.reservations 
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const getARequest = (token, id, updateHouseHoldList) => async dispatch => {
    try{

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            reservationId: id,
            sourceId: constant.SOURCEID
        }
        console.log('Vea pues: ', data)
        await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/show`, data, headers)
        .then((res) => {
            console.log('Last question: ', data)
            if(res.status === 200){
                dispatch({
                    type: GET_A_REQUEST,
                    payload: res.data.reservation
                })
                if(updateHouseHoldList === 'true'){
                    dispatch(getTaskActive(token))
                }
            }            
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const getDefaultFilterPros = (token, id) => async dispatch => {
    try{

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            reservationId: id
        }

        
        await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/filterPros`, data, headers)
        .then((res) => {
            if(res.status === 200){
                console.log('Validating original field: ', typeof res.data.filterPros, res.data.filterPros.hasOwnProperty('original'))
                if(res.data.filterPros.hasOwnProperty('original')){
                    dispatch({
                        type: GET_FILTER_PRO,
                        payload: []
                    })
                }else{
                    dispatch({
                        type: GET_FILTER_PRO,
                        payload: res.data.filterPros
                    })
                }
            }            
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const getFilterPro = (token, dataFilter, reservationId, duration, time) => async dispatch => {
    try{

        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const weekDates = []

        for(let i=0; i<time.length; i++){
            weekDates.push(time[i].week_date)
        }

        const data = {
            "reservationId": reservationId,
            "weekDates":weekDates,
            "duration": duration,
            "lastName": dataFilter.name,
            "email": dataFilter.email,
            "postCode": dataFilter.postalCode,
            "criterion": {
                "oven" : dataFilter.oven ? 1 : 0,
                "fridge" : dataFilter.fridge ? 1 : 0,
                "bed": dataFilter.bed ? 1 : 0,
                "vacuum": dataFilter.vacuum ? 1 : 0,
                "product_ecological": dataFilter.productEcological ? 1 : 0,
                "product_standard": dataFilter.productStandard ? 1 : 0,
                "cat": dataFilter.cat ? 1 : 0,
                "dog": dataFilter.dog ? 1 : 0
            },
            "availability": {
               "Mo": getIdAmPmProSchedule(dataFilter.timeClient.mo_am, dataFilter.timeClient.mo_pm),
               "Tu": getIdAmPmProSchedule(dataFilter.timeClient.tu_am, dataFilter.timeClient.tu_pm),
               "We": getIdAmPmProSchedule(dataFilter.timeClient.we_am, dataFilter.timeClient.we_pm),
               "Th": getIdAmPmProSchedule(dataFilter.timeClient.th_am, dataFilter.timeClient.th_pm),
               "Fr": getIdAmPmProSchedule(dataFilter.timeClient.fr_am, dataFilter.timeClient.fr_pm),
               "Sa": getIdAmPmProSchedule(dataFilter.timeClient.sa_am, dataFilter.timeClient.sa_pm),
               "Su": getIdAmPmProSchedule(dataFilter.timeClient.su_am, dataFilter.timeClient.su_pm)
           }
        }

        const content = JSON.stringify(data);
        await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/search`, content, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch({
                    type: GET_FILTER_PRO,
                    payload: res.data.pros
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const sendReservation = (token, profs, id) => async dispatch => {
    try{
        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        let p = {}

        for(let index in profs){
            p[profs[index]] = true
        }

        const data = {
            "reservationId": id,
            "proIds": p
        }

        const content = JSON.stringify(data);
        await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/assign`, content, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch(getDefaultFilterPros(token, id))
                dispatch(setAlert('la requête a été envayer', 'success'))
            }
        })
        .catch((error) => {
            console.log(error)
        })        

    }catch(error){
        console.log(error)
    }
}

export const proChosenByAdmin = (token, prId, comment) => async dispatch => {
    try{
        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = {
            "prId": prId,
            "comment": comment
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/selectPro`, data, headers)
        .then((res) => {

            if(res.status === 200){
               dispatch(setAlert('Le pro a été choisi', 'success'))
            }
        })
        .catch((error) => {
            console.log(error)
        })        
    }catch(error){
        console.log(error)
    }
}

export const offerRemoveByAdmin = (token, prId) => async dispatch => {
    try{
        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = {
            "prId": prId
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/offerRemove`, data, headers)
        .then((res) => {

            if(res.status === 200){
               dispatch(setAlert('Le pro a été supprimé', 'success'))
            }
            
        })
        .catch((error) => {
            console.log(error)
        })        

    }catch(error){
        console.log(error)
    }
}

export const getActivitiesByPro = (token, date) => async dispatch => {
    try{
        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        await axios.get(`${config.API_SERVER}/api/v1/admin/planning/${date}`, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch({
                    type: GET_RESERVATIONS_BY_PRO,
                    payload: res.data.plannings
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })        

    }catch(error){
        console.log(error)
    }
}

export const sendRequestToAllPro = (token) => async dispatch => {
    try{
        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }
        await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/switch`, {}, headers)
        .then((res) => {
            if(res.status === 200){
             dispatch(setAlert('Les transactions ont été envoyées avec succès', 'success'))            
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}