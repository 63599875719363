import DashboardLayout from "../../../layout/Layout";

import ConciergePostalCode from "./ConciergePostalCode";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Divider } from "@material-ui/core";
import PaperLayout from "../../../share/layout/PaperLayout";
import { Container } from "@material-ui/core";
const useStyle = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: "bold",
        marginRight: "20px",
        fontSize: "1.8rem",
        marginBottom: "20px",
    },

    gridSize: {
        height: "650px",
        width: "100%",
    },
}));

const IndexPoste = () => {
    const classes = useStyle();
    return (
        <DashboardLayout>
            <Container maxWidth="md">
                <PaperLayout>
                    <Typography
                        component="h1"
                        variant="h6"
                        noWrap
                        className={classes.title}
                    >
                        Codes Postaux
                    </Typography>
                    <Divider />
                    <Box className={classes.gridSize}>
                        <ConciergePostalCode />
                    </Box>
                </PaperLayout>
            </Container>
        </DashboardLayout>
    );
};

export default IndexPoste;
