import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CandidateTable from '../Tables/CandidateTable';
import Processing from '../Processing';
import { getAllCandidates, unlockCandidateAction, cleanCandidateAction } from '../../../store/actions/candidateAction';
import config from '../../../config.json';
import axios from 'axios';
import { formateDate, formateDateT } from '../../share/librery/librery'

import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: "flex",
    justifyContent: 'row',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '30px',
    borderRadius: '25px'
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridSize: {
    height: '80%',
    width: '100%'
  },
  title: {
    fontSize: '3.8rem',
    fontWeight: 'bold',
    marginLeft: '1.4rem'
  },
  divider: {
    height: '2px',
    backgroundColor: 'black',
    width: '100%'
  }
}))

const CandidateList = () => {
  const { token, userAdmin, candidates, processing }  = useSelector(
    state => ({
      token: state.auth.access_token,
      userAdmin: state.auth.userAdmin,
      candidates: state.candidate.candidates,
      processing: state.candidate.processing
    })
  );
  
  const dispatch = useDispatch();

  const classes = useStyles();
  const [searchfirstName, setSearchfirstName] = useState('');
  const [searchlastName, setSearchlastName] = useState('');
  const [searchcreated_at, setSearchcreated_at] = useState('');
  const [searchtime_interview, setSearchtime_interview] = useState('');
  const [searchtimezone, setSearchtimezone] = useState('');
  const [searchcity, setSearchcity] = useState('');
  //const token = useSelector(store => store.auth.access_token);
  const { recruitment_delete, recruitment_unlock, recruitment_update } = userAdmin.authority;

  const permit = { recruitment_delete, recruitment_unlock, recruitment_update };

  //It is part of the API no delete.
  const [rows, setRows] = useState([]);
  const [filterrows, setFilterrows] = useState([]);

  const headCells = [
    //{ id: 'id', numeric: false, disablePadding: true, label: 'id', searchOption: false },
    { id: 'firstName', numeric: false, disablePadding: true, label: 'Prénom', searchOption: true },
    { id: 'lastName', numeric: false, disablePadding: true, label: 'Nom', searchOption: true },
    { id: 'timezone', numeric: false, disablePadding: true, label: 'Timezone', searchOption: true },
    { id: 'city', numeric: false, disablePadding: true, label: 'City', searchOption: true },
    { id: 'created_at', numeric: false, disablePadding: true, label: 'Date création', searchOption: true },
    { id: 'time_interview', numeric: false, disablePadding: true, label: 'Date visio', searchOption: true },
    { id: 'statut', numeric: false, disablePadding: true, label: 'Statut', searchOption: true },
  ];

  useEffect(() => {
    return () => {
      dispatch(cleanCandidateAction());
    };
  }, [dispatch])

  useEffect(() => {

    const initialFilter = (fils) => {

      let filterRows = fils.map((row) => {
        row.created_at = formateDateT(row.credential.created_at);
        row.time_interview = (row.interview_status === 0) ? '-' : formateDate(row.time_interview);
        row.firstName = row.credential.firstName;
        row.lastName = row.credential.lastName;
        //row.uuid = row.credential.uuid;
        row.city = row.address.city;
        
        return row;
      })

      setFilterrows(filterRows);
      setRows(filterRows);
    }
    
    if (candidates === null) {
      dispatch(getAllCandidates(token));
    } else {
      const result = candidates;
      let arrayCandidate = result.filter(can => can.status_id <= 2);
      initialFilter(arrayCandidate);
    }

  }, [token, candidates, dispatch])

  const deleteCandidate = (id) => {
    const headers = {
      headers: { 'Authorization': `Bearer ${token}` }
    }
    const data = {
      id: id
    }
    axios.post(`${config.API_SERVER}/api/v1/candidate/remove`, data, headers)
      .then((res) => {
        let removeItem = rows.filter(item => item.id !== id);
        setFilterrows(removeItem);
        setRows(removeItem);

      }).catch((error) => {
        console.error(error)
      })
  }

  const unlockCandidate = (e, id, firstName, lastName) => {
    e.preventDefault();
    dispatch(unlockCandidateAction(id, token, firstName, lastName));
    let changeStatusItem = rows.filter(item => {
      if (item.id === id) {
        item.lock_status = 0;
      }
      return item;
    });
    setFilterrows(changeStatusItem);
    setRows(changeStatusItem);

  }

  const filter = () => {
    let filterRows = rows.filter((row) => row.firstName.toString().toLowerCase().indexOf(searchfirstName.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.lastName.toString().toLowerCase().indexOf(searchlastName.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.created_at.toString().toLowerCase().indexOf(searchcreated_at.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.time_interview.toString().toLowerCase().indexOf(searchtime_interview.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.timezone.toString().toLowerCase().indexOf(searchtimezone.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.city.toString().toLowerCase().indexOf(searchcity.toString().toLowerCase()) > -1);
    setFilterrows(filterRows);
  }

  const onChangeSearch = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case 'searchfirstName':
        setSearchfirstName(value);
        break;
      case 'searchlastName':
        setSearchlastName(value);
        break;
      case 'searchcreated_at':
        setSearchcreated_at(value);
        break;
      case 'searchtime_interview':
        setSearchtime_interview(value);
        break;
      case 'searchtimezone':
        setSearchtimezone(value);
        break;
      case 'searchcity':
        setSearchcity(value);
        break;
      default:
        break;
    }
  }
  /* Shouete vous confirme la supresion*/

  return (
    <Box>
      {
        (processing) 
        ? 
        (<Processing />) 
        : 
        (  
          <Paper component="form" className={classes.root} >
            <Typography variant="h5">CANDIDATURE</Typography>
            <Divider className={classes.divider} />
            <Box className={classes.gridSize}>

              <CandidateTable
                rows={filterrows}
                headCells={headCells}
                onChangeSearch={onChangeSearch}
                filter={filter}
                deleteCandidate={deleteCandidate}
                unlockCandidate={unlockCandidate}
                permit={permit}
              />
            </Box>
          </Paper>
        )
      }
    </Box>
  )

}

export default CandidateList;

//PE1NADA EPICA