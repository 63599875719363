import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import config from '../../../config.json';
import varConstant from "../../../constant.json"
import Paper from '@material-ui/core/Paper';
import {
    getACandidateAction,
    updateCandidatePhotoAction,
    updateReferenceCandidateAction,
    updateBgStatusCandidateAction,
    updateCandidateDateMailVisioAction,
    updateCandidateVisioStatusProgrammedAction,
    updateCandidateAvatarRejectsAction,
    updateCandidateStatusAction,
    unlockCandidateAction,
    //cleanCandidateAction,
    //lockCandidateAction
    //    unlockCandidateAction,
    //updateCandidateVisioDateProgrammedAction,
} from '../../../store/actions/candidateAction';
import { setAlert } from '../../../store/actions/alertAction';
import DashboardLayout from '../../layout/Layout';
//import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import SelectInput from '../../share/inputs/SelectInput';
import CandidateInfo from './CandidateInfo';
import CandidateRef from './CandidateRef';
import { Button, FormControl } from '@material-ui/core';
import CustomModal from './CustomModal';
import Annotations from './Annotations';
import { useDispatch, useSelector } from 'react-redux';
import Processing from '../Processing';
import { getUnixDate } from '../../share/librery/librery';
//import UnlockACandidate from './UnlockACandidate';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: 'row',
        flexDirection: 'column',
        alignItems: 'start',
        padding: '30px',
        borderRadius: '25px',
        fontSize: "2.1rem",
        '& label': {
            fontSize: '2rem',
            color: '#7f7f7f',
        }
    },
    title: {
        fontSize: '3.8rem',
        fontWeight: 'bold',
        marginLeft: '1.4rem'
    },
    headerTab: {
        color: '#000',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '& button:focus': {
            outline: '0px auto -webkit-focus-ring-color'
        },
        '& .MuiTab-root': {
            textTransform: 'none',
            fontFamily: 'sans-serif',
            fontSize: '2.1rem',
            color: '#7f7f7f',

        }
    },
    groutInputs: {
        '& .MuiFormControl-root': {
            marginRight: '1rem'
        }
    },
    alertSuccess: {
        backgroundColor: '#28cc8b',
        width: 'fit-content',
        marginBottom: '-53px'
    },
    alertError: {
        backgroundColor: '#f44336',
        width: 'fit-content',
        marginBottom: '-53px'
    },
    tabStyle: {
        '&>div>div':{
            padding: '16px 0px 0 0px'
        }
    }
}));

function Candidate() {

    const classes = useStyles();

    const { token, candidate:{candidate,notations, messages: messagesc } } = useSelector(
        state => ({
            token: state.auth.access_token,
            candidate: state.candidate
        })
    );
    
    const dispatch = useDispatch();
    const history = useNavigate();

    let { id, status_id } = useParams();
    const { VarButton } = varConstant
 
    const [dataForm, setDataForm] = useState({
        statusId: status_id,
        value: 0,
        change: false,
        referencesBlocked: false, 
        validateCandidate: false,
        validateMailVisio: false,
        btnBlocked: false,
        imgChanged: true,
        textPopup: '',
        openModal: false,
        popupRunsFunction: '',
        servicesList: '',
        how_know_us_list: {},
        initialState: {}
    })

    const { statusId, value, change, referencesBlocked, validateCandidate, validateMailVisio, imgChanged, textPopup, openModal, 
        popupRunsFunction, btnBlocked, servicesList, how_know_us_list, initialState } = dataForm

    let etatCandidate = [
        { id: 1, name: 'NEW', color: '#5087f5', buttonName: 'ACCEPTER' },
        { id: 2, name: 'EN COURS', color: '#f49e3a', buttonName: 'ACCEPTER' },
        { id: 3, name: 'ACCEPTÉ', color: '#3ad640', buttonName: 'ACCEPTER' },
        { id: 4, name: 'REJETÉ', color: '#f05c5c', buttonName: 'REFUSER' }
    ];

    const CandidateChecked = [{ id: '0', name: 'À vérifier', color: '#3ad640' }, { id: '1', name: 'Vérifié', color: '#3ad640' }];

    const getTodayDateAndTime = () => {
        let t = new Date();
        let year = t.getFullYear();
        let month = t.getMonth() + 1;
        month = (month < 10) ? "0" + month : month;
        let hours = (t.getHours() < 10) ? "0" + t.getHours() : t.getHours();
        let minutes = (t.getMinutes() < 10) ? "0" + t.getMinutes() : t.getMinutes();
        let day = (t.getDate() < 10) ? "0" + t.getDate() : t.getDate();
        let formDate = year + "-" + month + "-" + day + ' ' + hours + ':' + minutes;
        
        return formDate;
    }
    const [getFechaEmailSent, setGetDateEmailSent] = useState(getTodayDateAndTime());

    if(!localStorage.hasOwnProperty('candidateId')){
        if(initialState.lockStatus === 1){
            history('/recruitment/candidatelist');
        }
    }

    useEffect(() => {
        const unLock = () => {
            if(localStorage.hasOwnProperty('candidateId')){
                localStorage.removeItem('candidateId');
                dispatch(unlockCandidateAction(id, token, '', '', history));
            }
        }
        
        return () => {
            unLock();
            window.addEventListener("beforeunload", unLock());
        }
    }, [id, token, dispatch, history])
    
    const candidateUnlocked = e => {
        e.preventDefault();
        localStorage.removeItem('candidateId')
        localStorage.setItem('goToCandidateList', true)
        dispatch(unlockCandidateAction(id, token, '', '', history));
    }

    useEffect( () => {
        
        const getDateAt = (createDateAt) => {
            const createdAt = new Date(createDateAt);
            let year = createdAt.getFullYear();
            let month = createdAt.getMonth() + 1;
            month = month < 10 ? '0' + month : month;
            let day = (createdAt.getDate() < 10) ? "0" + createdAt.getDate() : createdAt.getDate();
            let formCreatedAt = day + "/" + month + "/" + year;
            return formCreatedAt;

        }
        
        const startServicesAndHnu = async () => {
            
                const obj = [];                
                const res = await fetch(`${config.API_SERVER}/api/howknowus/fr`, {method: 'GET'})
                
                if(res.status === 200){
                    const r = await res.json();
                    const data = r.data
                    for(const index in data){

                        obj.push({id: index, name: data[index]})
                    }
                    
                }

                let ser =  []
                const resServices = await fetch(`${config.API_SERVER}/api/services/all`, {method: 'GET'})

                if(resServices.status === 200){
                    const data = await resServices.json();
                    for(let index in data){                        
                        if(index !== 'id'){
                            ser[data[index].id] = data[index].fr
                        }
                    }
                }

                setDataForm((dataForm) => {
                    return {
                        ...dataForm,
                        how_know_us_list: obj,
                        servicesList: ser
                    }
                }) 
            
        }

        if (candidate === null) {
            startServicesAndHnu()
            .then(() => {
                dispatch(getACandidateAction(id, token));
            })

        } else {
            //setIsDirty();
            let stateAvatar = 0;
            for(let index in notations){
                if(notations[index].category === 'avatar'){
                    let i = notations[index].info.search("refusé la photo");
                    if (i > -1) {
                        stateAvatar = 1;
                    }
                    i = notations[index].info.search("validé la photo");
                    if (i > -1) {
                         stateAvatar = 2;
                    }
                }
            }

            let arrServices = []
            let ma = '';

            for(let i=0; i<candidate.services.length;i++){
                ma = `${candidate.services[i].id}-${candidate.services[i].fr}`
                arrServices.push(ma)
            }

            const initialState = {
                statusId: candidate.status_id,
                avatarUrl: candidate.avatar.url,
                avatarStatus: stateAvatar,
                createdAt: candidate.credential.created_at,
                firstName: candidate.credential.firstName,
                lastName: candidate.credential.lastName,
                lockStatus: candidate.lock_status,
                email: candidate.credential.email,
                street: candidate.address.rue,
                city: candidate.address.city,
                province: candidate.address.province,
                country: candidate.address.country,
                postcode: candidate.address.postcode,
                timezone: candidate.timezone,
                telephone: candidate.credential.telephone,
                authorization_status: candidate.authorization_status,
                smartphone_status: candidate.smartphone_status,
                //health_status: candidate.health_status,
                //healthDescription: candidate.health_description,
                dateOfBirth: candidate.credential.date_of_birth,
                experience_status: candidate.experience_status,
                referLastName1: candidate.reference.length > 0 ? candidate.reference[0].last_name : '',
                referFirstName1: candidate.reference.length > 0 ? candidate.reference[0].first_name : '',
                referEmail1: candidate.reference.length > 0 ? candidate.reference[0].email : '',
                referTelephone1: candidate.reference.length > 0 ? candidate.reference[0].telephone : '',
                referCompany1: candidate.reference.length > 0 ? candidate.reference[0].company : '',
                referPosition1: candidate.reference.length > 0 ? candidate.reference[0].position : '',
                stillEmployed1: candidate.reference.length > 0 ? candidate.reference[0].still_employed : '',
                referContactStatus1: candidate.reference.length > 0 ? candidate.reference[0].contact_status : 0,
                workRegurary: candidate.motivation.work_regularly,
                workExtra: candidate.motivation.work_extra,
                extraIncome: candidate.motivation.extra_income,
                visibility: candidate.motivation.visibility,
                concept: candidate.motivation.concept,
                how_know_us: candidate.how_know_us.id,
                emailInterviewStatus: candidate.email_interview_status,
                timeInterviewMail: candidate.time_interview_mail !== null ? candidate.time_interview_mail : getFechaEmailSent,
                timeInterview: candidate.time_interview !== null ? candidate.time_interview : "",
                interviewStatus: candidate.interview_status,
                backgroundCheckStatus: candidate.background_check_status,
                formCreatedAt: getDateAt(candidate.credential.created_at),
                servicesChosen: arrServices,
                totalReferences: candidate.reference.length,
                self_description: candidate.self_description
            }

            initialState.referLastName2 = candidate.reference.length === 2 ? candidate.reference[1].last_name : '';
            initialState.referFirstName2 = candidate.reference.length === 2 ? candidate.reference[1].first_name : '';
            initialState.referEmail2 = candidate.reference.length === 2 ? candidate.reference[1].email : '';
            initialState.referTelephone2 = candidate.reference.length === 2 ? candidate.reference[1].telephone : '';
            initialState.referCompany2 = candidate.reference.length === 2 ? candidate.reference[1].company : '';
            initialState.referPosition2 = candidate.reference.length === 2 ? candidate.reference[1].position : '';
            initialState.stillEmployed2 = candidate.reference.length === 2 ? candidate.reference[1].still_employed : '';
            initialState.referContactStatus2 = candidate.reference.length === 2 ? candidate.reference[1].contact_status : 0;
            initialState.files = [];
            initialState.preview = '';
            
            for(let index in candidate.language) {
                initialState[candidate.language[index].name] = true
            }

            let ser = []
            for(let index in candidate.service){
                if(index !== 'id'){
                    ser.push(index) 
                }
            }
            initialState.service = ser
            let referencesBlocked = false
            if(candidate.reference.length > 0){
                if(candidate.reference[0].contact_status === 1){
                    referencesBlocked = true
                }
            }
            
            setDataForm((dataForm) => {
                return {
                    ...dataForm,
                    referencesBlocked,
                    btnBlocked: initialState.statusId >= 3 ? true : false,
                    statusId: candidate.status_id > 2 ? candidate.status_id : dataForm.statusId,
                    initialState: {
                        ...dataForm.initialState,
                        ...initialState
                    }
                }
            })
        }
    }, [id, token, candidate, notations, getFechaEmailSent, dispatch])

    const handleChangeTabs = (event, newValue) => {
        setDataForm({
            ...dataForm,
            value: newValue
        })
    };

    const handleChange = (e) => {
        let value = e.target.value;
        switch (e.target.type) {
            case 'text':
            case 'date':
            case 'datetime-local':
            case 'select-one':
                e.preventDefault();
                initialState[e.target.name] = value;
                break;
            case 'radio':
                e.preventDefault();
                initialState[e.target.name] = parseInt(value);
                break;
            case "checkbox":
                const checkedv = e.target.checked ? 1 : 0;
                initialState[e.target.name] = checkedv;
                break;
            default:
                break;
        }
        setDataForm({
            ...dataForm,
            change: !change
        });
    }

    const setImages = (e) => {
        e.preventDefault();
        initialState['files'] = e.target.files;
        initialState['avatarStatus'] = 2;
        initialState['preview'] = URL.createObjectURL(e.target.files[0]);
        setDataForm({
            ...dataForm,
            imgChanged: false
        })
    }

    const savePhoto = (e) => {
        e.preventDefault();
        setDataForm({
            ...dataForm,
            imgChanged: true
        })

        dispatch(updateCandidatePhotoAction(id, token, initialState.files));
    }

    const avatarRejects = (e) => {
        e.preventDefault();

        setDataForm({
            ...dataForm,
            initialState: {
                ...dataForm.initialState,
                avatarStatus: 1
            }
        })

        dispatch(updateCandidateAvatarRejectsAction(id, token, 'fr', candidate));
    }

    const closePopup = (e) => {
        e.preventDefault();

        if(popupRunsFunction === 'updateBgStatus'){
            setDataForm({
                ...dataForm,
                initialState: {
                    ...dataForm.initialState,
                    backgroundCheckStatus: 0
                },
                validateCandidate: false,
                openModal: false
            })
        }
        
        if(popupRunsFunction === 'updateReference'){

            setDataForm({
                ...dataForm,
                initialState: {
                    ...dataForm.initialState,
                    referContactStatus1: 0, 
                    referContactStatus2: 0
                },
                validateCandidate: false,
                openModal: false
            })
        }

    }

    const openPopup = (e) => {
        //e.preventDefault();
        switch (e.target.name) {
            case 'interviewStatus':
                if (initialState.emailInterviewStatus) {

                    if (getUnixDate(initialState.timeInterviewMail) <= getUnixDate(initialState.timeInterview)) { 
                        
                        setDataForm({
                            ...dataForm,
                            initialState: {
                                ...dataForm.initialState,
                                [e.target.name]: e.target.checked
                            },
                            textPopup: "Voulez-vous planifier une date pour la vidéoconférence?",
                            openModal: true,
                            popupRunsFunction: 'updateVideoStatusProgrammed'
                        })     
        
                        
                    } else {
                        dispatch(setAlert('La date ne peut pas être inférieure à Mail Visio', 'error'));
                    }
                } else {

                    setDataForm({
                        ...dataForm,
                        initialState: {
                            ...dataForm.initialState, 
                            interviewStatus: false
                        }
                    })    
                    dispatch(setAlert(`Aucun e-mail n'a été envoyé pour planifier la Mail Visio`, 'error'));
                }
                break;
            case 'backgroundCheckStatus':
                e.preventDefault();
                setDataForm({
                    ...dataForm,
                    textPopup: "Do you want to change the value?",
                    openModal: true,
                    popupRunsFunction: 'updateBgStatus',
                    initialState: {
                        ...dataForm.initialState, 
                        [e.target.name]: e.target.value
                    }
                })

                break;
            case 'referContactStatus1':
            case 'referContactStatus2':
                const refer = e.target.checked ? 1 : 0;
                let displayPopup = false;

                if(e.target.name === 'referContactStatus1'){
                    if(refer === 1 && initialState.referContactStatus2 === 1){
                        displayPopup = true;
                    }
                    if(refer === 1 && initialState.referFirstName2.trim() === ''){
                        displayPopup = true;
                    }
                    
                }

                if(e.target.name === 'referContactStatus2'){
                    if(refer === 1 && initialState.referContactStatus1 === 1){
                        displayPopup = true;
                    }
                }

                let textPopupVar= ''
                let openModalVar= false
                let popupRunsFunctionVar= ''

                if(displayPopup){
                    textPopupVar= "voulez-vous mettre à jour le statut du contact 1?"
                    openModalVar= true
                    popupRunsFunctionVar= 'updateReference'
                }

                setDataForm({
                    ...dataForm,
                    textPopup: textPopupVar,
                    openModal: openModalVar,
                    popupRunsFunction: popupRunsFunctionVar,
                    initialState: {
                        ...dataForm.initialState, 
                        [e.target.name]: refer
                    }
                })   

                break;

            default:
                break;
        }
    }

    const runFunctionFromYesPopupButton = (e) => {
        e.preventDefault();
        switch (popupRunsFunction) {
            case 'updateVideoStatusProgrammed':
                updateVideoStatusProgrammed();
                break;
            case 'updateBgStatus':
                updateBgStatus();
                break;
            case 'updateReference':
                updateReference();
                break;
            default:
                break;
        }
        setDataForm({
            ...dataForm,
            openModal: false
        })
    }

    const updateVideoStatusProgrammed = () => {
        setDataForm({
            ...dataForm,
            openModal: false,
            initialState: {
                ...dataForm.initialState, 
                interviewStatus: 1
            }
        })
        
        dispatch(updateCandidateVisioStatusProgrammedAction(id, token, candidate));

    }

    const updateReference = () => {
        const name = initialState.firstName + ' ' + initialState.lastName;
        setDataForm({
            ...dataForm,
            referencesBlocked: true
        })            

        dispatch(updateReferenceCandidateAction(id, token, initialState.referContactStatus1, initialState.referContactStatus2, initialState.statusId, name, candidate));
    }

    const updateBgStatus = () => {
        const name = initialState.firstName + ' ' + initialState.lastName;
        dispatch(updateBgStatusCandidateAction(id, token, initialState.statusId, name, candidate));
    }

    const updateMailVisio = (e) => {
        
        e.preventDefault();
        initialState.timeInterviewMail = getTodayDateAndTime();
        setGetDateEmailSent(getTodayDateAndTime());

        let referStatus2 = 0;
        if (initialState.referFirstName2.length > 0) {
            if (initialState.referContactStatus2 === 1) {
                referStatus2 = 1;
            }
        }else{
            referStatus2 = 1;
        }

        if (initialState.backgroundCheckStatus === 1 && 
            ((initialState.referContactStatus1 === 1 && referStatus2 === 1) || initialState.totalReferences === 0)) {

            initialState.emailInterviewStatus = 1;
            dispatch(updateCandidateDateMailVisioAction(id, token, initialState.timeInterviewMail, 'fr', initialState.firstName, initialState.lastName, initialState.email, initialState.telephone, candidate));

        } else {

            setDataForm({
                ...dataForm,
                validateCandidate: true
            })
    
            dispatch(setAlert('Entrez les informations en rouge', 'error'));

        }

    }

    const updateStatus = (e, name) => {
        e.preventDefault();
        setDataForm({
            ...dataForm,
            validateMailVisio: false
        })


        let message = '';
       
        if (initialState.statusId === '3') {
            if (initialState.backgroundCheckStatus === 0
                || initialState.emailInterviewStatus === 0
                || initialState.interviewStatus === 0
                || (initialState.referContactStatus1 === 0 && initialState.totalReferences > 1)
            ) {
                message = message + `Pour changer le statut, il est nécessaire d'ajouter toutes les informations`;
            }
        }

        if (message.length === 0) {
            if(initialState.statusId > 2){
                setDataForm({
                    ...dataForm,
                    btnBlocked: true
                })
            } 
            dispatch(updateCandidateStatusAction(id, token, initialState.statusId, name, candidate, history, VarButton.condition2));

        } else {
            dispatch(setAlert(message, 'error'));
        }
    }

    if (initialState.statusId > 1) {
         etatCandidate = etatCandidate.filter(item => item.id > 1);
    }

    let getColor = etatCandidate.filter((item) => item.id === parseInt(initialState.statusId));

    return (
        <DashboardLayout>

            <CustomModal
                openModal={openModal}
                closePopup={closePopup}
                content={textPopup}
                //runFunction={scheduledVideo ? addVideoDateProgrammed : updateBgStatus}
                runFunction={runFunctionFromYesPopupButton}
            />
            {
                (Object.keys(initialState).length === 0) ?
                (
                    <Processing />
                )
                :
                (
                    
                    <Paper component="form" className={classes.root} >
                        {true && (
                            <Grid container spacing={3}>
                                <Grid item md={12}>
                                    <Box mb={0}>
                                        <Typography component="h1" variant="h1" noWrap className={classes.title}>
                                            Candidature
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={7} mb={0}>
                                    <Box display="flex" flexDirection="column" ml={2}>
                                        <Box mb={1}><Typography variant="h5">Date de candidature</Typography></Box>
                                        <span> {initialState.formCreatedAt} </span>
                                    </Box>

                                </Grid>
                                <Grid item md={5}>
                                    <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mr={4}>
                                    {(initialState.statusId <= 3) && (
                                        <Box mr={2}>
                                            <Button
                                                variant="contained"
                                                onClick={(e) => candidateUnlocked(e)}
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                Quitter
                                            </Button>                                            
                                        </Box>
                                    )}

                                    <Box>
                                        <Box mb={1}><Typography variant="h5">État de la candidature</Typography></Box>
                                            <FormControl disabled={btnBlocked}>
                                                <SelectInput
                                                    id="statusId"
                                                    name="statusId"
                                                    data={etatCandidate}
                                                    colorFont={{ color: getColor.length > 0 ? getColor[0].color : '#000', fontWeight: 'bold' }}
                                                    onChange={(e) => handleChange(e)}
                                                    defaultValue={initialState.statusId}
                                                    disabled={btnBlocked}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={12}>
                                    <AppBar position="static" className={classes.headerTab}>
                                        <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example">
                                            <Tab label="Candidature" {...a11yProps(0)} />
                                            <Tab label="Parcours" {...a11yProps(1)} />
                                            <Tab label="Annotations" {...a11yProps(2)} />
                                        </Tabs>
                                    </AppBar>
                                    <Box className={classes.tabStyle}>
                                        <TabPanel value={value} index={0}>
                                            {(Object.entries(initialState).length > 0) && (
                                                <CandidateInfo
                                                    initialState={initialState}
                                                    CandidateChecked={CandidateChecked}
                                                    handleChange={handleChange}
                                                    updateMailVisio={updateMailVisio}
                                                    setImages={setImages}
                                                    savePhoto={savePhoto}
                                                    openPopup={openPopup}
                                                    validateCandidate={validateCandidate}
                                                    validateMailVisio={validateMailVisio}
                                                    avatarRejects={avatarRejects}
                                                    getFechaEmailSent={getFechaEmailSent}
                                                    imgChanged={imgChanged}                                                    
                                                />
                                            )}
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            {(Object.entries(initialState).length > 0) && (
                                                <CandidateRef
                                                    handleChange={handleChange}
                                                    initialState={initialState}
                                                    how_know_us_list={how_know_us_list}
                                                    openPopup={openPopup}
                                                    validateCandidate={validateCandidate}
                                                    referencesBlocked={referencesBlocked}
                                                    servicesList={servicesList}
                                                />
                                            )}
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <Annotations
                                                id={id}
                                                token={token}
                                                candidate={candidate}
                                                annotations={notations}
                                                messages={messagesc}
                                            />
                                        </TabPanel>
                                    </Box>
                                    <Box display="flex" justifyContent="space-around">
                                    {   
                                        ((statusId <= 2 && initialState.statusId > 2)) && (
                                                    
                                            <Button
                                                variant="contained"
                                                onClick={(e) => updateStatus(e, getColor[0].name)}
                                                style={{
                                                    backgroundColor: getColor.length > 0 ? getColor[0].color : '#000',
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                    width: '250px'
                                                }}
                                            >
                                                { 
                                                    getColor.length > 0 ? getColor[0].buttonName : ''
                                                }
                                            </Button>
                                                  
                                        )                                              
                                    }
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                )
            }
            {/* { Prompt } */}
        </DashboardLayout>
    );
}

export default Candidate;