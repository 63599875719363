import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Box } from '@material-ui/core'


import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PersonPinCircleOutlinedIcon from '@material-ui/icons/PersonPinCircleOutlined';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import constant from '../../constant.json';
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

//const drawerWidth = 240;
const drawerWidth = '17%';

let varMenu = {
    menu: '',
    submenu: ''
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        '& .MuiList-padding': {
            paddingTop: '90px',

        }
    },
    BoxSubMenu: {
        '& .MuiList-root': {
            padding: '0 !important',
            margin: '0 0 0 50px !important',

        }

    },
    menuStyle: {
        color: '#7f7f7f',
        '& span': {
            fontSize: '20px'
        }
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(10),
    },
    optionChosen: {
        backgroundColor: '#2880f9',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2880f9'
        }
    },
    option: {
        color: '#7f7f7f',
    },
    linkMenuStyle: {
        marginTop: '12px', 
        marginBottom: '12px'
    },
    linkMenuStyleLocked: {
        pointerEvents: 'none'
    }
}));

export default function DrawerMenu({ authority }) {
    const classes = useStyles();

    const history = useNavigate();
    const location = useLocation();
    const [optSelected, setOptSelected] = useState(varMenu.menu)
    

    const pathArray = location.pathname.split('/');
    let menuBlocked = false;
    if (pathArray.length >= 4) {
        if (pathArray[2] === 'candidatelist') {
            if (parseInt(pathArray[4]) < 3) {
                menuBlocked = true;
            }
        }
    }
    
    const addOptions = (e, title) => {
        e.preventDefault()
        
        const index = optSelected.indexOf(title)
        if(index > -1){
            varMenu={
                menu: '',
                submenu: ''
            }
            setOptSelected([])
        }else{
            varMenu={
                menu: title,
                submenu: ''
            }
            setOptSelected(title)
        }
    }

    const runLink = (e, link) => {
        e.preventDefault()
        varMenu.submenu= link
        history(link)
    }

    let menu = constant.SIDE_MENU;
    let classNameMenu = classes.linkMenuStyle
    if (menuBlocked) {
        classNameMenu = classNameMenu + ' ' + classes.linkMenuStyleLocked
        menu = constant.SIDE_MENU_DISABLED;
    } 


    const sideMenu = [];
    let obj = {};

    obj = menu.DASHBOARD;
    obj.elemBefore = () => <DashboardOutlinedIcon />
    sideMenu.push(obj);
    
    if (authority.reservation) {
        obj = menu.RESERVATION;
        obj.elemBefore = () => <img style={{width: '20px'}} src='images/menu_icons/icon-reservation.svg' height='26' width='26' alt='' />
        sideMenu.push(obj);
    }

    if (authority.services) {
        obj = menu.SERVICES;
        obj.elemBefore = () => <SettingsOutlinedIcon />
        sideMenu.push(obj);
    }

    if (authority.access) {
        obj = menu.ACCESS;
        obj.elemBefore = () => <FingerprintIcon />
        sideMenu.push(obj);
    }

    if (authority.contacts) {
        obj = menu.CONTACT;
        obj.elemBefore = () => <img style={{width: '23px'}}  src='images/menu_icons/icon-contact.svg' height='26' width='26' alt='' />
        sideMenu.push(obj);
    }

    // obj = menu.LANDINPAGE;
    // obj.elemBefore = () => <AirlineSeatReclineNormalIcon />
    // sideMenu.push(obj);

    if (authority.recruitment) {
        obj = menu.RECRUITMENT;
        obj.elemBefore = () => <img style={{width: '23px'}} src='images/menu_icons/icon-recrutement.svg' height='26' width='26' alt='' />
        sideMenu.push(obj);
    }

    obj = menu.PRIX;
    obj.elemBefore = () => <MonetizationOnOutlinedIcon />
    sideMenu.push(obj);


    if (authority.mailbox) {
        obj = menu.MAILBOX;
        obj.elemBefore = () => <img style={{width: '23px'}} src='images/menu_icons/icon-mailbox.svg' height='26' width='26' alt='' />
        sideMenu.push(obj);
    }

    if (authority.billing) {
        obj = menu.BILLING;
        obj.elemBefore = () => <img style={{width: '23px'}} src='images/menu_icons/icon-paiement.svg' alt='' />
        sideMenu.push(obj);
    }

    // if (authority.pages) {
    //     obj = menu.PAGES;
    //     obj.elemBefore = () => <img src='images/menu_icons/icon-page.svg' alt='' />
    //     sideMenu.push(obj);
    // }

    if (authority.analityc) {
        obj = menu.ANALITYC;
        obj.elemBefore = () => <img style={{width: '23px'}} src='images/menu_icons/icon-analytics.svg' alt='' />
        sideMenu.push(obj);
    }

    // obj = menu.CODES_POSTAUX;
    // obj.elemBefore = () => <PersonPinCircleOutlinedIcon fontSize={'large'} />
    // sideMenu.push(obj);

    obj = menu.CONCIERGE;
    obj.elemBefore = () => <img style={{width: '23px'}} src='images/menu_icons/icon-analytics.svg' alt='' />
    sideMenu.push(obj);

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={classes.toolbar} />
                <List>
                    {
                        sideMenu.map((row, index) => {
                            let subMenu = row?.subNav ?? []
                            const ifChosen = optSelected.indexOf(row.itemId)

                            return (
                                <Box key={index} >
                                    <ListItem className={classNameMenu} button onClick={(e) => addOptions(e, row.itemId)}>
                                        <ListItemIcon>{row.elemBefore()}</ListItemIcon>
                                        <ListItemText className={classes.menuStyle} primary={row.title} />
                                        {
                                            varMenu.menu === row.itemId
                                            ?
                                                (<KeyboardArrowUpIcon />)
                                            :
                                                (<KeyboardArrowDownIcon />)
                                        }
                                        
                                    </ListItem>
                                    <Box className={classes.BoxSubMenu} hidden={!(ifChosen >-1)}>
                                        <List>
                                            {
                                                subMenu.map((subRow, indexSubMenu) => {
                                                    return (
                                                        <ListItem className={varMenu.submenu ===subRow.itemId ? classes.optionChosen : classes.option} button key={indexSubMenu} onClick={(e) => runLink(e, subRow.itemId)} >
                                                            <ListItemText primary={subRow.title} />
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </List>
            </Drawer>
        </div>
    );
}