import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//Matarial-UI
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./assets/css/material-ui/configThemes";
import SignIn from "./components/auth/SignIn";
import HomePage from "./components/views/HomePage";
import DashboardServiceSchedule from "./components/views/planning/Index";
import ClientList from "./components/views/ClientList";
import Client from "./components/views/contact/client/Index";
import ClientMsg from "./components/views/message/clients/Client";
import ProMsg from "./components/views/message/pro/Pro";
// import Pro from "./components/views/contact/pro/Index"
import Pro from "./components/views/contact/professional/Index";
import ProfessionalsList from "./components/views/ProfessionalsList";
import LeadClient from "./components/views/LeadClient";
import LeadProfessional from "./components/views/LeadProfessional";
import Candidate from "./components/views/candidate/Candidate";
import Planningvisio from "./components/views/Planningvisio";
import Admin from "./components/views/admin/Admin";
import Archive from "./components/views/Archive";
import AdminList from "./components/views/AdminList";
//import PrivateRoute from "./components/routing/PrivateRoute";
import Profile from "./components/views/profile/Profile";
import ResetPasswordForm from "./components/auth/ResetPasswordForm";
import BookingInterview from "./components/views/candidate/BookingInterview";
import Reschedule from "./components/views/candidate/Reschedule";
import UnavailabilityForm from "./components/views/admin/UnavailabilityForm";
import ResetPasswordAdminForm from "./components/views/admin/ResetPasswordAdminForm";
import CreateMailBox from "./components/views/mailbox/CreateMailBox";
import UpdateMailBox from "./components/views/mailbox/UpdateMailBox";
import MailBoxList from "./components/views/MailBoxList";
import SendMail from "./components/views/mailbox/SendMail";
import ServicesList from "./components/views/ServicesList";
//import Service from "./components/views/Service/Service";
import RateList from "./components/views/RateList";
import TariffTester from "./components/views/tariff/TariffTester";

import Request from "./components/views/request/Request";
import TaskList from "./components/views/TaskList";
import DashboardRequestList from "./components/views/request-list/Index";
import DashboardRequestListTerminer from "./components/views/request-Terminer/Index";
import DashboardRequestListAffecter from "./components/views/request-NonAffecter/Index";
import DashboardRequestListAssigner from "./components/views/request-NonAssigner/Index";
import DashboardRequestListLitige from "./components/views/request-Litige/Index";
import DashboardRequestListAnnuler from "./components/views/request-Annuler/Index";
import DashboardRequestListAccepter from "./components/views/request-Accepter/Index";
import DashboardRequestListAccepterPro from "./components/views/request-AccepterPro/Index";
import DashboardRequestListEnAttente from "./components/views/request-EnAttente/Index";
import DashboardRequestListNouvelle from "./components/views/request-Nouvelle/Index";
import DashboardCandidateList from "./components/views/candidate-list/Index";
import TaxesList from "./components/views/TaxesList";

import IndexPoste from "./components/views/concierge/ConciergePostal/Index";
import EndingRequest from "./components/views/contact/client/request/EndingRequest";
import PromoCode from "./components/views/PromoCode";
const App = () => {
    console.log("Date updated: Jun - 11 - 2024 - 16:26");
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route
                        exact
                        path="/concierge/PostalCode"
                        element={<IndexPoste/>}
                    /> 
                    <Route
                        exact
                        path="/housework"
                        element= {<TaskList/>}
                    />
                    <Route
                        exact
                        path="/promocode"
                        element= {<PromoCode />}
                    />
                    <Route exact path="/taxes" element= {<TaxesList/>} />
                    <Route
                        exact
                        path="/services"
                        element= {<ServicesList/>}
                    />
                    <Route
                        exact
                        path="/reservation/request/:id/:reservationStatusId/:updateHouseHoldList/:requestStatus"
                        element= {<Request/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests"
                        element= {<DashboardRequestList/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests-complete"
                        element= {<DashboardRequestListTerminer/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests-NotAffected"
                        element= {<DashboardRequestListAffecter/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests-NotAssigned"
                        element= {<DashboardRequestListAssigner/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests-Litigate"
                        element= {<DashboardRequestListLitige/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests-Canceled"
                        element= {<DashboardRequestListAnnuler/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests-Accepted"
                        element= {<DashboardRequestListAccepter/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests-AcceptPro"
                        element= {<DashboardRequestListAccepterPro/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests-Pending"
                        element= {<DashboardRequestListEnAttente/>}
                    />
                    <Route
                        exact
                        path="/reservation/requests-New"
                        element= {<DashboardRequestListNouvelle/>}
                    />
                    <Route
                        exact
                        path="/reservation/services"
                        element= {<DashboardServiceSchedule/>}
                    />
                    <Route
                        exact
                        path="/contact/client"
                        element= {<ClientList/>}
                    />
                    <Route
                        exact
                        path="/contact/client/:id"
                        element= {<Client/>}
                    />
                    <Route
                        exact
                        path="/contact/client/finish/:id"
                        element= {<EndingRequest/>}
                    />
                    <Route
                        exact
                        path="/contact/pro/:id"
                        element= {<Pro/>}
                    />
                    <Route
                        exact
                        path="/contact/professional"
                        element= {<ProfessionalsList/>}
                    />
                    <Route
                        exact
                        path="/landingpage/client"
                        element= {<LeadClient/>}
                    />
                    <Route
                        exact
                        path="/landingpage/professional"
                        element= {<LeadProfessional/>}
                    />

                    <Route
                        exact
                        path="/recruitment/candidatelist/:id/:status_id"
                        element= {<Candidate/>}
                    />
                    <Route
                        exact
                        path="/recruitment/candidatelist"
                        element= {<DashboardCandidateList/>}
                    />
                    <Route
                        exact
                        path="/recruitment/planningvisio"
                        element= {<Planningvisio/>}
                    />
                    <Route
                        exact
                        path="/recruitment/archive"
                        element= {<Archive/>}
                    />

                    <Route
                        exact
                        path="/rete_list"
                        element= {<RateList/>}
                    />
                    <Route
                        exact
                        path="/tester"
                        element= {<TariffTester/>}
                    />
                    <Route
                        exact
                        path="/tariff/:cityId"
                        element= {<TariffTester/>}
                    />
                    <Route
                        exact
                        path="/message/client"
                        element= {<ClientMsg/>}
                    />
                    <Route
                        exact
                        path="/message/pro"
                        element= {<ProMsg/>}
                    />
                    <Route
                        exact
                        path="/dashboard"
                        element= {<HomePage/>}
                    />
                    <Route
                        exact
                        path="/adminlist"
                        element= {<AdminList/>}
                    />
                    <Route exact path="/admin" element= {<Admin/>} />
                    <Route exact path="/admin/:id" element= {<Admin/>} />
                    <Route exact path="/profile" element= {<Profile/>} />
                    <Route
                        exact
                        path="/activity"
                        element= {<UnavailabilityForm/>}
                    />
                    <Route
                        exact
                        path="/list_mailbox"
                        element= {<MailBoxList/>}
                    />
                    <Route
                        exact
                        path="/compose_email"
                        element= {<SendMail/>}
                    />
                    <Route
                        exact
                        path="/create-mailbox/:id"
                        element= {<CreateMailBox/>}
                    />
                    <Route
                        exact
                        path="/update-mailbox/:id/:description"
                        element= {<UpdateMailBox/>}
                    />
                    <Route
                        exact
                        path="/reset-password-form/:token/:email"
                        element= {<ResetPasswordForm/>}
                    />
                    <Route
                        exact
                        path="/reschedule/:email"
                        element= {<Reschedule/>}
                    />
                    <Route
                        exact
                        path="/calendar-form/:email"
                        element= {<BookingInterview/>}
                    />
                    <Route
                        exact
                        path="/reset-password-admin-form"
                        element= {<ResetPasswordAdminForm/>}
                    />

                    <Route exact path="/login" element= {<SignIn/>} />
                    <Route exact path="/" element= {<SignIn/>} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
