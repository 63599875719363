import { Box, FormControlLabel, Radio, RadioGroup, Typography, Checkbox, FormControl, Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '../../../components/share/inputs/Input';
import SelectInput from '../../share/inputs/SelectInput';
import InputCustomPhone from '../../share/inputs/InputCustomPhone';
import MultipleSelect from '../../share/inputs/MultipleSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    groupCheckBoxes: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'space-between'
    },
    classCheckBox: {
        //width: "291px",
        '& .MuiSvgIcon-root': {
            fontSize: '3rem'
        },
        '& .MuiTypography-root': {
            fontSize: '2rem'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        },
        '@media (max-width:1200px)': {
            '& span': {
                fontSize: '16px'
            }
        }
    },
    howToKnowUs: {
        width: '270px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
            boxShadow: '-1px 4px 6px 3px #80808047',
            borderRadius: '4px'
        },
        '& svg.MuiNativeSelect-icon': {
            color: theme.palette.primary.main,
        }

    },
    boxReferences: {

        display: 'inline-block',
        maxWidth: '300px',
        marginBottom: '15px',
        '@media (max-width:768px)': {
            maxWidth: '768px',
            width: '100%',
            marginRight: '0',
            marginBottom: '30px',
            '& .MuiFormControl-root': {
                maxWidth: '768px'
            }
        }

    },
    errorMessage: {
        color: '#dc3545',
        fontSize: '1.6rem',
        marginTop: '-8px'
    },
    btnDate: {
        textTransform: 'none',
        fontSize: '14px',
        color: '#fff',
        '@media (max-width:1500px)': {
            fontSize: '13px',
        },
        '@media (max-width:1410px)': {
            fontSize: '10px',
            padding: '6px 8px',
        },
    },
    error: {
        '& .MuiFormControlLabel-root': {
            border: '1px solid #dc3545',
        }
    }
}));

function CandidateRef(props) {

    const classes = useStyles();
    const { handleChange, initialState, openPopup, how_know_us_list, validateCandidate, referencesBlocked, servicesList } = props;

    let checkStyle = classes.classCheckBox;
    if (validateCandidate) {
        checkStyle = checkStyle + ' ' + classes.error;
    }
    const variable = 0;

    return (
        <div>
            <React.Fragment>

                <Box mb={2} display="flex" flexDirection="column" justifyContent="space-between" >
                    <Box>
                        <Box mb={1}><Typography variant="h5">Expérience en tant que préposé au ménage</Typography></Box>

                        <RadioGroup className={classes.classCheckBox} row aria-label="gender" name="experience_status"
                            value={(initialState.experience_status === 1) ? "1" : "0"}
                        >
                            <FormControlLabel labelPlacement="end" value="1" control={<Radio color="primary" />} label="Oui" />
                            <FormControlLabel labelPlacement="end" value="0" control={<Radio color="primary" />} label="Non" />
                        </RadioGroup>
                    </Box>

                    <Box mt={2} mb={2}>
                        <MultipleSelect 
                            id="servicesChosen" 
                            label="Services"
                            handleChange = { handleChange } 
                            servicesList={ servicesList } 
                            servicesChosen = { initialState.servicesChosen}
                            disabled={ true }
                        />
                    </Box>
                </Box>

                <Box>
                    <Typography variant="h5">Personnes référentes</Typography>
                    {/* <Typography variant="h5">{t("ProForm.FormPersonalBackground.referencesSubTitle")}</Typography> */}

                    <Box mt={2} mb={1}><Typography variant="h5">Premier contact</Typography></Box>

                    <Grid container>

                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <Input
                                    id="referFirstName1" placeholder="Nom" size="small" width={'100%'}
                                    defaultValue={initialState.referFirstName1} onBlur={handleChange} variant="outlined"
                                    error={''} readOnly={true} />
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <Input
                                    id="referLastName1" placeholder="Prénom" size="small" width={'100%'}
                                    defaultValue={initialState.referLastName1} onBlur={handleChange} variant="outlined"
                                    error={''} readOnly={true} />
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>

                                <Input
                                    id="referEmail1" placeholder='Courriel' size="small" width={'100%'}
                                    defaultValue={initialState.referEmail1} onBlur={handleChange} variant="outlined"
                                    error={''} readOnly={true} />
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <InputCustomPhone
                                    name = 'referTelephone1'
                                    country={'ca'}
                                    label="Cellulaire"
                                    value={initialState.referTelephone1}              
                                    disableCountryCode={false}
                                    disableAreaCodes={true}
                                    disableDropdown={true}
                                    onChange={e => handleChange(e)}
                                    variant="filled"
                                    size="small"
                                    width= {'100%'}                                                      
                                    error=''
                                    readOnly={true}
                                />      
                            </Box>
                        </Grid>

                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <Input
                                    id="referCompany1" placeholder="Société" size="small" width={'100%'}
                                    defaultValue={initialState.referCompany1} onBlur={handleChange} variant="outlined"
                                    error={''} readOnly={true} />
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>

                                <Input
                                    id="referPosition1" placeholder='Fonction' size="small" width={'100'}
                                    defaultValue={initialState.referPosition1} onBlur={handleChange} variant="outlined"
                                    error={initialState.referPosition1 === null ? '' : ''}
                                    readOnly={true} />
                            </Box>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Box display='flex' flexDirection='row'>

                                <RadioGroup className={classes.classCheckBox} aria-label="gender" name="experience_status" 
                                    value={(initialState.stillEmployed1 === 1) ? "1" : "0"}
                                >
                                    <FormControlLabel 
                                        labelPlacement="end" 
                                        value="1" 
                                        control={<Radio color="primary" />} label="Je travaille actuellement " 
                                    />
                                    <FormControlLabel 
                                        labelPlacement="end" 
                                        value="0" 
                                        control={<Radio color="primary" />} label="J’ai quitté l’entreprise" 
                                    />
                                </RadioGroup>                                

                                <Box display="flex" flexDirection="column">
                                    <Box className={(validateCandidate && !initialState.referContactStatus1 && initialState.referFirstName1.length > 0) ? checkStyle : classes.classCheckBox}>

                                        <FormControlLabel
                                            disabled={referencesBlocked || initialState.referFirstName1.length === 0 ? true : false}
                                            control={
                                                <Checkbox
                                                    onClick={(e) => openPopup(e)}
                                                    name="referContactStatus1"
                                                    color="primary" checked={initialState.referContactStatus1 === 1 ? true : false}
                                                    className={classes.root}
                                                />
                                            }
                                            label="Contact"
                                        />

                                    </Box>
                                    {(validateCandidate && !initialState.referContactStatus1 === 0 && initialState.referFirstName1.length > 0) && (
                                        <Box ml={2}>
                                            <span className={classes.errorMessage}>Requi</span>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                        </Grid>

                    </Grid>

                    <Box mt={0} mb={1}><Typography variant="h5">Deuxième contact</Typography></Box>


                    <Grid container>

                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <Input
                                    error={(variable > 0 && variable === 1) ? "Ce champ est obligatoire" : ""}
                                    id="referFirstName2" placeholder="Nom" size="small" width={'100%'}
                                    defaultValue={initialState.referFirstName2} onBlur={handleChange} variant="outlined" readOnly={true} />

                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <Input
                                    error={(variable > 0 && variable === 1) ? 'Ce champ est obligatoire' : ""}
                                    id="referLastName2" placeholder="Prénom" size="small" width={'100%'}
                                    defaultValue={initialState.referLastName2} onBlur={handleChange} variant="outlined" readOnly={true} />

                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <Input
                                    error={(variable > 0 && variable === 1) ? 'formErrors.step2.referEmail1' : ""}
                                    id="referEmail2" placeholder='Courriel' size="small" width={'100%'}
                                    defaultValue={initialState.referEmail2} onBlur={handleChange} variant="outlined" readOnly={true} />

                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <InputCustomPhone
                                    name = 'referTelephone2'
                                    label=""
                                    country={'ca'}
                                    value={initialState.referTelephone2}              
                                    disableCountryCode={false}
                                    disableAreaCodes={true}
                                    disableDropdown={true}
                                    onChange={e => handleChange(e)}
                                    variant="filled"
                                    size="small"
                                    width= {'100%'}                                                      
                                    error=''
                                    readOnly={true}
                                />       
                            </Box>
                        </Grid>

                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <Input
                                    error={(variable > 0 && variable === 1) ? 'Ce champ est obligatoire' : ""}
                                    id="referCompany2" placeholder="Société" size="small" width={'100%'}
                                    defaultValue={initialState.referCompany2} onBlur={handleChange} variant="outlined" readOnly={true} />

                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <Input
                                    error={(variable > 0 && variable === 1) ? 'Ce champ est obligatoire' : ""}
                                    id="referPosition2" placeholder='Fonction' size="small" width={'100%'}
                                    defaultValue={initialState.referPosition2} onBlur={handleChange} variant="outlined" readOnly={true} />

                            </Box>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Box display='flex' flexDirection='row'>

                                <RadioGroup className={classes.classCheckBox} aria-label="gender" name="experience_status" 
                                    value={(initialState.stillEmployed2 === 1) ? "1" : "0"}
                                >
                                    <FormControlLabel labelPlacement="end" value="1" control={<Radio color="primary" />} label="Je travaille actuellement" />
                                    <FormControlLabel labelPlacement="end" value="0" control={<Radio color="primary" />} label="J’ai quitté l’entreprise" />
                                </RadioGroup>  

                                <Box display="flex" flexDirection="column">
                                    <Box className={(validateCandidate && !initialState.referContactStatus2 && initialState.referFirstName2.length > 0) ? checkStyle : classes.classCheckBox}>
                                        <FormControlLabel 
                                            disabled={referencesBlocked || initialState.referFirstName2.length === 0 ? true : false}
                                            control={
                                                <Checkbox
                                                    onClick={(e) => openPopup(e)}
                                                    name="referContactStatus2"
                                                    color="primary" checked={initialState.referContactStatus2 === 1 ? true : false}
                                                    className={classes.root}
                                                />
                                            }
                                            label="Contact"
                                        />
                                    </Box>
                                    {(validateCandidate && !initialState.referContactStatus2 === 0 && initialState.referFirstName2.length > 0) && (
                                        <Box ml={2}>
                                            <span className={classes.errorMessage}>Requi</span>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>


                    <Box>
                        <Box mt={2}><Typography variant="h5">Pourquoi veut-il intégrer Tiggidoo ?</Typography></Box>
                        <Box className={classes.groupCheckBoxes}>
                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="workRegurary" color="primary" checked={initialState.workRegurary ? true : false} className={classes.root} />}
                                    label="Travail régulier"
                                />
                            </Box>
                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="workExtra" color="primary" checked={initialState.workExtra ? true : false} className={classes.root} />}
                                    label="Travail d'appoint"
                                />
                            </Box>
                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="visibility" color="primary" checked={initialState.visibility ? true : false} className={classes.root} />}
                                    label="Visibilité"
                                />
                            </Box>
                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="concept" color="primary" checked={initialState.concept ? true : false} className={classes.root} />}
                                    label="Concept"
                                />
                            </Box>
                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="extraIncome" color="primary" checked={initialState.extraIncome ? true : false} className={classes.root} />}
                                    label="Revenus supplémentaires"
                                />
                            </Box>
                        </Box>
                        {(!initialState.workRegurary && !initialState.workExtra && !initialState.visibility && !initialState.concept && !initialState.extraIncome) && (
                            <Box className={classes.errorMessage}>Choisir au moins une option</Box>
                        )}
                    </Box>

                    <Box className={classes.groupInfo} mt={3}>
                        <Box><Typography variant="h5">Comment nous as-t-il connu ?</Typography></Box>

                        <FormControl disabled={true}>
                            <SelectInput
                                id="how_know_us"
                                name="how_know_us"
                                data={how_know_us_list}
                                colorFont={{ color: '#000', fontWeight: 'bold' }}
                                onChange={handleChange}
                                defaultValue={initialState.how_know_us}
                                readOnly={true}
                            />
                        </FormControl>
                    </Box>
                </Box>

            </React.Fragment >

        </div >
    );
}

export default CandidateRef;