import axios from "axios";
import config from "../../config.json";
import { setAlert } from "./alertAction";

import { GETTING_ALL_CONTACTS, GETTING_A_CONTACT } from "./typesAction";

export const contactClient = (token) => async (dispatch) => {
    try {
        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };

        axios
            .get(`${config.API_SERVER}/api/v1/admin/client/all`, headers)
            .then((res) => {
                dispatch({
                    type: GETTING_ALL_CONTACTS,
                    payload: res.data.clients.list,
                });
            });
    } catch (error) {
        console.log(error);
    }
};

export const contactPro = (token) => async (dispatch) => {
    try {
        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };

        axios
            .get(`${config.API_SERVER}/api/v1/admin/pro/all`, headers)
            .then((res) => {
                dispatch({
                    type: GETTING_ALL_CONTACTS,
                    payload: res.data.pros.list,
                });
            });
    } catch (error) {
        console.log(error);
    }
};

export const getAContactClient = (token, id) => async (dispatch) => {
    try {
        const data = {
            clientId: id,
        };

        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };

        axios
            .post(
                `${config.API_SERVER}/api/v1/admin/client/show`,
                data,
                headers
            )
            .then((res) => {
                dispatch({
                    type: GETTING_A_CONTACT,
                    payload: res.data.client,
                });
            });
    } catch (error) {
        console.log(error);
    }
};

export const getAContactPro = (token, id) => async (dispatch) => {
    try {
        const data = {
            proId: id,
        };

        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };

        axios
            .post(`${config.API_SERVER}/api/v1/admin/pro/show`, data, headers)
            .then((res) => {
                dispatch({
                    type: GETTING_A_CONTACT,
                    payload: res.data.pro,
                });
            });
    } catch (error) {
        console.log(error);
    }
};

export const updateHouseHoldListReservation =
    (token, newHouseHoldList, method, clientId) => (dispatch) => {
        try {
            const array = {};
            for (let index in newHouseHoldList) {
                array[newHouseHoldList[index]] = true;
            }

            const data = {
                clientId: clientId,
                prId: method.id,
                enterMethod: method.enter_method,
                enterComment: method.enter_comment,
                householdListIds: array,
            };

            const headers = {
                headers: { Authorization: `Bearer ${token}` },
            };

            axios
                .post(
                    `${config.API_SERVER}/api/v1/admin/client/benefit/update`,
                    JSON.stringify(data),
                    headers
                )
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(
                            setAlert(
                                "Données mises à jour, avec succès",
                                "success"
                            )
                        );
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

export const updateTelephoneClient =
    (token, clientId, telephone) => (dispatch) => {
        try {
            const data = {
                clientId: clientId,
                telephone: telephone,
            };

            const headers = {
                headers: { Authorization: `Bearer ${token}` },
            };

            axios
                .post(
                    `${config.API_SERVER}/api/v1/admin/client/telephone/update`,
                    data,
                    headers
                )
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(
                            setAlert(
                                "Données mises à jour, avec succès",
                                "success"
                            )
                        );
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

export const updateTelephonePro = (token, proId, telephone) => (dispatch) => {
    try {
        const data = {
            proId: proId,
            telephone: telephone,
        };

        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };

        axios
            .post(
                `${config.API_SERVER}/api/v1/admin/pro/tel/update`,
                data,
                headers
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(
                        setAlert("Données mises à jour, avec succès", "success")
                    );
                }
            });
    } catch (error) {
        console.log(error);
    }
};

export const updateAvailabilityPro =
    (token, proId, enableTime) => (dispatch) => {
        try {
            const content = {
                proId: proId,
                availability: {
                    Mo: enableTime.Mo,
                    Tu: enableTime.Tu,
                    We: enableTime.We,
                    Th: enableTime.Th,
                    Fr: enableTime.Fr,
                    Sa: enableTime.Sa,
                    Su: enableTime.Su,
                },
            };
            const headers = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const data = JSON.stringify(content);
            //console.log('Availability: ', data)

            axios
                .post(
                    `${config.API_SERVER}/api/v1/admin/pro/availability/update`,
                    data,
                    headers
                )
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(
                            setAlert(
                                "Données mises à jour, avec succès",
                                "success"
                            )
                        );
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
