import React from 'react';
import DashboardLayout from '../../layout/Layout';
import CandidateList from './CandidateList';



const DashboardCandidateList = () => {
  return(
    <DashboardLayout>
        <CandidateList />
    </DashboardLayout>
  );
}

export default DashboardCandidateList;