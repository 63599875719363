import React, { useState } from 'react'
import { Avatar, Box, Button, makeStyles, Typography } from '@material-ui/core'
import Input from '../../../../share/inputs/Input'
import { getTelephoneFormat } from '../../../../share/librery/Regex'
import { updateTelephonePro } from '../../../../../store/actions/contactAction'
import CheckBoxCustom from '../../../../share/inputs/CheckBoxCustom';
import { useDispatch } from 'react-redux'
import {  useNavigate } from 'react-router-dom';
import { updateCandidateStatusAction} from "../../../../../store/actions/candidateAction"
import varConstant from "../../../../../constant.json"
import {  useEffect, useRef } from 'react';
const useStyle = makeStyles (() => ({
    labelChkStyle: {
        '& label':{
            textTransform: 'capitalize'
        },
        '& svg':{
            marginTop: '0'
        }
    }
}))


const InfoPro = ({ info, token }) => { 
    const history = useNavigate();  
    const classes = useStyle()
    const [count, setCount] = useState(0)
    const dispatch = useDispatch()

    const { VarButton } = varConstant

    const [telephone, setTelephone] = useState(info.credential.telephone)

    const updateTelephone = e => {
        e.preventDefault()
        
        dispatch(updateTelephonePro(token, info.id, telephone))
    }

    const handleChange = e => {
        e.preventDefault()
        setTelephone(e.target.value)
    }

    const handleChangeCheck = e => {

    }
    const resetMail = (e) => {
      
        setCount(1)
        clearTimer(getDeadTime());  
            dispatch(updateCandidateStatusAction(info.id, token, info.status_id, "ACCEPTÉ", null, history, VarButton.condition1));
            // dispatch(setAlert(`Mail envoyer a ${info.credential.firstName}`, 'success'));
    }
    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00:00');
       useEffect(() => {
		clearTimer(getDeadTime());
    }, []); 
	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total, hours, minutes, seconds
		};
	}


	const startTimer = (e) => {
		let { total, hours, minutes, seconds }
					= getTimeRemaining(e);
		if (total >= 0) {

			setTimer(
				(hours > 9 ? hours : '0' + hours) + ':' +
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)
		}
	}


	const clearTimer = (e) => {


		setTimer('00:01:00');

		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const getDeadTime = () => {
		let deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + 60);
		return deadline;}
    
    return (
        <Box>
            <Box display="flex" flexDirection="row" >
                <Box display="flex" flexDirection="column" mr={5} style={{width: '40%'}}>
                    <Box mb={4}> 
                        <Input
                            id="lastName"
                            placeholder=""
                            label="Nom"
                            size="small"
                            defaultValue={info.credential.lastName}
                            width="100%"
                            error=""
                            readOnly={true}
                        />
                    </Box>
                    <Box mb={4}> 
                        <Input
                            id="firstName"
                            placeholder=""
                            label="Prénom "
                            size="small"
                            defaultValue={info.credential.firstName}
                            width="100%"
                            error=""
                            readOnly={true}

                        />
                    </Box>
                    <Box mb={4}> 
                        <Input
                            id="email"
                            placeholder=""
                            label="Courriel"
                            size="small"
                            defaultValue={info.credential.email}
                            width="100%"
                            error=""
                            readOnly={true}

                        />
                    </Box>
                    <Box mb={4}> 
                        <Box display="flex">
                            <Input
                                id="telephone"
                                placeholder=""
                                label="Clellulaire"
                                size="small"
                                defaultValue={getTelephoneFormat(telephone)}
                                onBlur={e => handleChange(e)}
                                width="50%"
                                error=""
                                readOnly={false}
                            />
                            <Button variant="contained" color="primary" onClick={ e => updateTelephone(e) }>UPDATE TELEPHONE</Button>
                        </Box>    
                    </Box>
                    <Box mb={4}> 
                        <Input
                            id="city"
                            placeholder=""
                            label="Address"
                            size="small"
                            defaultValue={`${info.address.rue}, ${info.address.city}, ${info.address.province}, ${info.address.country}, ${info.address.postcode}`}
                            width="100%"
                            error=""
                            readOnly={true}
                        />
                    </Box>
                </Box>    

                <Box display="flex" flexDirection="column">
                    <Box mb={4}> 
                        <Avatar style={{ backgroundColor: 'transparent', border: '2px solid #2880fb', width: '100px', height: '100px' }}
                            alt={info.credential.firstName} src={info.avatar.url}
                        >

                        </Avatar>
                        
                        <Box mt={2}>
                            <Typography variant="h6">Description Todoo</Typography>
                        </Box>
                        <Box style={{color: '#28cc8b', width: '500px'}}>
                            {info.self_description}
                        </Box>
                    </Box>
                    <Box mb={4}> 
                        <Box className={classes.labelChkStyle}>
                            <Typography variant="h6">Langues parlées</Typography>
                            <Box display="flex">
                            {
                                info.language.map((row, index) => {
                                    return(
                                        <CheckBoxCustom
                                            key={index}
                                            name={row.name}
                                            label={row.name}
                                            value={true}
                                            handleChange={e=>handleChangeCheck(e)}
                                        />               
                                    )
                                })                                
                            }
                            </Box>
                        </Box>

                    </Box>
                    <Box mb={4}> 
                        <Box className={classes.labelChkStyle}>
                            <Typography variant="h6">Liste de service</Typography>
                            <Box display="flex">
                            {
                                info.services.map((row, index) => {
                                    return(
                                        <CheckBoxCustom
                                            key={index}
                                            name={row.fr}
                                            label={row.fr}
                                            value={true}
                                            handleChange={e=>handleChangeCheck(e)}
                                        />            
                                    )
                                })                                
                            }
                            </Box>
                        </Box>
                    </Box>
                </Box>     


            </Box>  
            
            <Box  hidden = {info.active > 0}>
                <Box display = "flex" marginTop = "10px" >
                    <Button disabled={count > 0 && timer > '00:00:00' } variant="contained" color="primary" onClick= {e => resetMail(e) }>
                        Envoyer mail  reset password
                    </Button>  
                    <Box marginLeft = {"10px"}>
                        <p hidden={count === 0 }>{timer}</p>
                    </Box> 
                </Box>  
            </Box>
                 
        </Box>

           
    )
}

export default InfoPro
