import axios from 'axios';
import config from '../../config.json';
import { setAlert } from './alertAction';
import { GET_TEMPLATES, PROCESSING, GET_EMAILS } from './typesAction';

export const createTemplate = (token, name, file, json, history) => async dispatch => {
    try {        
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const f = new FormData();
        f.append('name', name);
        f.append("html", file);
        f.append("json", json);
        
        await axios.post(`${config.API_SERVER}/api/v1/admin/template/create`, f, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch(setAlert('Le modèle a été créé', 'success'))
            }  
        })
        .then(() => {
            history('/list_mailbox')
        }).catch((error) => {
            dispatch(setAlert('Le modèle a déjà été créé', 'error'))
            console.log(error);
        })
    } catch (error) {
        console.log(error);
    }
}

export const updateTemplate = (token, id, file, json) => async dispatch => {
    try {
        
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const f = new FormData();
        f.append('id', id);
        f.append("html", file);
        f.append("json", json);
        
        //console.log('Este es el template: ', json)

        await axios.post(`${config.API_SERVER}/api/v1/admin/template/update`, f, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch(setAlert('Le modèle a été mis à jour', 'success'))
            }  
        }).catch((error) => {
            console.log(error);
        })
    } catch (error) {
        console.log(error);
    }
}

export const getTemplates = (token) => async dispatch => {
    try {
        
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }
       
        await axios.get(`${config.API_SERVER}/api/v1/admin/template/all`, headers)
        .then((res) => {
            dispatch({
                type: GET_TEMPLATES,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error);
        })
    } catch (error) {
        console.log(error);
    }
}

export const deleteTemplates = (token, id) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING
        });
        
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data ={
            id: id
        }
       
        await axios.post(`${config.API_SERVER}/api/v1/admin/template/delete`, data, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch(setAlert('Le modèle a été supprimé', 'success'))
                dispatch(getTemplates(token));
            }            
        }).catch((error) => {
            console.log(error);
        })
    } catch (error) {
        console.log(error);
    }
}

export const sendEmail = (token, to,  cc, title,  subject, name) => async dispatch => {
    try {

        dispatch({
            type: PROCESSING
        });
        
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data ={
            to: to,
            cc: cc,
            subject: subject,
            title: title,
            templateName: name,
            content: name
        }
        //console.log(data)
        await axios.post(`${config.API_SERVER}/api/v1/admin/mailbox/send`, data, headers)
        .then((res) => {    
            console.log(res)
        }).catch((error) => {
            console.log(error);
        })

    } catch (error) {
        console.log(error);
    }
}

export const getAllEmails = (token) => async dispatch =>{
    try {
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        let emailArray = []

        axios.get(`${config.API_SERVER}/api/v1/candidate/all`, headers)
        .then(res => {

            const can = res.data.candidates
            for(let i in can){
                emailArray[i] = {  //can[i].credential.email
                    email: can[i].credential.email,
                    name: `${can[i].credential.firstName} ${can[i].credential.lastName}`
                }

            }

        })
        .then(res => {

            axios.get(`${config.API_SERVER}/api/v1/admin/super/all`, headers)
            .then(res => {
                const users = res.data.users
                let index = emailArray.length
                for(let i in users){
                    emailArray[index] = { //users[i].email
                        email: users[i].email,
                        name: `${users[i].first_name} ${users[i].last_name}`
                    }
                    index++
                }
            })

        })
        .then(res => {
            dispatch({
                type: GET_EMAILS,
                payload: emailArray
            })
        })
        .catch(error => {
            console.log(error);
        })

    } catch (error) {
        console.log(error)
    }
}