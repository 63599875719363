import { 
    PROCESSING, 
    FAILED_PROCESS, 
    GETTING_CANDIDATE_SUCCESS, 
    GETTING_ALL_CANDIDATE_SUCCESS, 
    GETTING_ALL_CANDIDATES_ACEPTED_AND_REJETED,
    CANDIDATE_SUCCESSFULLY_UPDATED, 
    AVATAR_STATUS_UPDATED_SUCCESSFULLY, 
    AVATAR_UPDATED_SUCCESSFULLY, 
    AVATAR_VALIDATED_SUCCESSFULLY, 
    DATE_INTERVIEW_MAIL_UPDATED_SUCCESSFULLY, 
    STATUS_INTERVIEW_UPDATED_SUCCESSFULLY, 
    USER_SUCCESSFULLY_UNLOCKED, 
    GET_MESSAGES,
    ADD_MESSAGES, 
    GET_NOTATIONS_CANDIDATE, 
    GET_MESSAGES_CANDIDATE, 
    CANCEL_VISIO,
    STATUS_UPDATED_SUCCESSFULLY 
} from '../actions/typesAction';

const initState = {
    processing: true,
    candidate: null,
    candidates: null,
    authError: null,
    status: null,
    messages: [],
    notations: []
};

/* This is an error function */
const candidateReducer = (state = initState, action) => {
    
    switch (action.type) {
        case PROCESSING:
            return initState;
        case GETTING_CANDIDATE_SUCCESS:
            return {
                ...state,
                candidate: action.payload.candidate,
                notations: action.payload.notations,
                messages: action.payload.messages,
                processing: false
            };
        
        case GETTING_ALL_CANDIDATE_SUCCESS:
            return {
                ...state,
                candidates: action.payload,
                processing: false
            };
        case GETTING_ALL_CANDIDATES_ACEPTED_AND_REJETED:
            return {
                ...state,
                candidates: [...state.candidates, ...action.payload],
                processing: false
            };
        case CANDIDATE_SUCCESSFULLY_UPDATED:
            return {
                candidate: action.payload.pro,
                candidates: null,
                authError: null,
                status: action.payload.status,
                processing: false,
            };
        case AVATAR_STATUS_UPDATED_SUCCESSFULLY:
            return {
                candidate: action.payload.pro,
                candidates: null,
                authError: null,
                status: action.payload.status,
                processing: false
            };
        case AVATAR_UPDATED_SUCCESSFULLY:
            return {
                ...state,
                processing: false
            };
        case AVATAR_VALIDATED_SUCCESSFULLY:
            return {
                ...state,
                status: action.payload.status,
                processing: false
            };
        case STATUS_UPDATED_SUCCESSFULLY:
            return {
                candidate: action.payload.pro,
                candidates: null,
                authError: null,
                status: action.payload.status,
                processing: false
            };
        case DATE_INTERVIEW_MAIL_UPDATED_SUCCESSFULLY:
            return {
                candidate: action.payload.pro,
                candidates: null,
                authError: null,
                status: action.payload.status,
                processing: false
            };        
        case STATUS_INTERVIEW_UPDATED_SUCCESSFULLY:
            return {
                candidate: action.payload.pro,
                candidates: null,
                authError: null,
                status: action.payload.status,
                processing: false
            };
        case USER_SUCCESSFULLY_UNLOCKED:
            return {
                ...state,
                candidate: null,
                status: action.payload.status,
                processing: false
            };
        case FAILED_PROCESS:
            return initState;
        case GET_MESSAGES:
            return {
                ...state,
                messages: action.payload.messages,
                processing: false
            };
        case ADD_MESSAGES:
            return {
                ...state,
                messages: action.payload.messages,
                processing: false
            };
        case GET_NOTATIONS_CANDIDATE: 
            return {
                ...state,
                notations: action.payload.notations,
                processing: true 
            };
        case GET_MESSAGES_CANDIDATE: 
            return {
                ...state,
                messages: action.payload.messages,
                processing: false
            };
        case CANCEL_VISIO: 
            return {
                candidate: null,
                candidates: null,
                authError: null,
                status: action.payload.status
            };            
        default:
            return initState;
    }
}

export default candidateReducer;