import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import  { useState } from 'react'
//import { Redirect } from 'react-router-dom';
import Header from "./Header";
import DrawerMenu from "./DrawerMenu";
import BodyWrapper from "./BodyWrapper";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    BodyWrapper: {
     position:"absolute"   
    },
    drawer: {
        width: '17%',
        boxShadow: '11px 0px 6px -9px rgba(0,0,0,0.6)',
        zIndex: '300'
    },
    content: {
        width: '83%',
        padding: '130px 30px 0 30px',
        backgroundColor: "#e0dfdf",
        '@media (max-width:1366px)': {
            padding: '110px 30px 0 30px',
        }
    },
    Bulle: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        color:"#fff",
        backgroundColor: theme.palette.primary.main,
    }
    
}));

const DashboardLayout = ({ children, userAdmin }) => {

    const classes = useStyles();
    const history = useNavigate();
    const firstName = userAdmin !== undefined ? userAdmin.first_name : '';
    const lastName = userAdmin !== undefined ? userAdmin.last_name : '';

    let urlAvatar = '';
    let position = '';
    let authority = [];

    authority = userAdmin.authority;
    if (userAdmin.avatar !== null) {
        urlAvatar = userAdmin.avatar?.url ?? 'images/default_man.png';
    } else {
        urlAvatar = 'images/default_man.png';
    }

    position = userAdmin.position;

    if (localStorage.hasOwnProperty('actionComeFrom')) {
        let actionComeFrom = localStorage.getItem('actionComeFrom');
        if (actionComeFrom) {
            localStorage.removeItem('actionComeFrom')
            history('/profile');
        }
    }
    // const [openLink, setOpenLink] = useState(false);

    // const OpenChat = () => {
    //   setOpenLink(!openLink);
    // };
    return (
        <BodyWrapper className={classes.BodyWrapper}>
            <Header firstName={firstName} lastName={lastName} position={position} urlAvatar={urlAvatar} />
            <Box display="flex">
                <Box className={classes.drawer} >
                    <DrawerMenu authority={authority} />
                </Box>
                <Box className={classes.content}>
                    {children}
                </Box>
            </Box>
           
        </BodyWrapper>
    )
}

const maptStateToProps = state => ({
    
    userAdmin: state.auth.userAdmin
})

export default connect(maptStateToProps, {})(DashboardLayout);