import axios from 'axios';
import { setAlert } from './alertAction';
import config from '../../config.json';

import { 
    PROCESSING, 
    FAILED_PROCESS, 
    GETTING_CANDIDATE_SUCCESS, 
    GETTING_ALL_CANDIDATE_SUCCESS, 
    GETTING_ALL_CANDIDATES_ACEPTED_AND_REJETED,
    CANDIDATE_SUCCESSFULLY_UPDATED, 
    AVATAR_STATUS_UPDATED_SUCCESSFULLY, 
    AVATAR_UPDATED_SUCCESSFULLY, 
    AVATAR_VALIDATED_SUCCESSFULLY, 
    DATE_INTERVIEW_MAIL_UPDATED_SUCCESSFULLY, 
    STATUS_INTERVIEW_UPDATED_SUCCESSFULLY, 
    //USER_SUCCESSFULLY_UNLOCKED,  
    GET_MESSAGES, 
    ADD_MESSAGES, 
    GET_NOTATIONS_CANDIDATE, 
    GET_MESSAGES_CANDIDATE, 
    CANCEL_VISIO, 
    STATUS_UPDATED_SUCCESSFULLY
} from './typesAction';
import { logOutTokenExpired } from './authAction';
import { interviewDateExists } from './planningvisioAction';

export const cleanCandidateAction = () => async dispatch => {
    dispatch({
        type: PROCESSING,
    });
}

export const getAllCandidatesArchived = (token) => async dispatch => {
    try {

        dispatch({
            type: PROCESSING,
        });

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        axios.get(`${config.API_SERVER}/api/v1/candidate/archive`, headers)
        .then((res) => {
            dispatch({
                type: GETTING_ALL_CANDIDATE_SUCCESS,
                payload: res.data.candidates
            })
        })
        .then(() => {

            axios.get(`${config.API_SERVER}/api/v1/candidate/refuse`, headers)
            .then(resp => {
                dispatch({
                    type: GETTING_ALL_CANDIDATES_ACEPTED_AND_REJETED,
                    payload: resp.data.candidates
                })
            }).catch((error) => {
                console.log(error);
            })

        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }
}

export const getAllCandidates = (token) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const { data, status } = await axios.get(`${config.API_SERVER}/api/v1/candidate/all`, headers)
        if(status === 200){
            dispatch({
                type: GETTING_ALL_CANDIDATE_SUCCESS,
                payload: data.candidates
            })
        }else{
            dispatch({
                type: GETTING_ALL_CANDIDATE_SUCCESS,
                payload: []
            })
        }

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }
}

const getgNotations = async (id, headers) => {
    let notations = [];
    let { data, status } = await axios.get(`${config.API_SERVER}/api/v1/candidate/notations/${id}`, headers)

    if(status === 200) {
        const arrayM = data.notations;
        for (let index in arrayM) {
            notations[arrayM[index].id] = arrayM[index];
        }
    }

    return notations
}



const getDialogue = async (id, headers) => {
    let messages = [];
    let { data, status } = await axios.get(`${config.API_SERVER}/api/v1/candidate/dialogue/${id}`, headers)

    if(status === 200) {
        const arrayM = data.dialogue;
        for (let index in arrayM) {
            messages[arrayM[index].id] = arrayM[index];
        }
    }

    return messages
}

const getInfoCandidate = async (dataApi, headers) => {

    let candidate = null;
    let { data, status } = await axios.post(`${config.API_SERVER}/api/v1/candidate/show`, dataApi, headers)
    
    if(status === 200) {
        candidate = data.candidate
    }

    return candidate
} 

export const getACandidateAction = (id, token) => async dispatch => {

    try {
        
        const dataApi = {
            id: id
        }

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        let notations = await getgNotations(id, headers);
        let messages = await getDialogue(id, headers);
        const candidate = await getInfoCandidate(dataApi, headers)
        
        if(candidate !== null){
            if(candidate.lock_status === 0 && candidate.status_id <= 2){
                await dispatch(lockCandidateAction(id, token));
            }
            dispatch({
                type: GETTING_CANDIDATE_SUCCESS,
                payload: { candidate: candidate, notations: notations, messages: messages }
            })
        }

        // let lockStatus = null;
        // let candidateState = null;
        // await axios.post(`${config.API_SERVER}/api/v1/candidate/show`, dataApi, headers)
        // .then((res) => {
            
        //     lockStatus = res.data.candidate.lock_status;
        //     candidateState = res.data.candidate.status_id;
        //     dispatch({
        //         type: GETTING_CANDIDATE_SUCCESS,
        //         payload: { candidate: res.data.candidate, notations: notations, messages: messages }
        //     })
        // })
        // .then(() => {
        //     if(lockStatus === 0 && candidateState <= 2){
        //         dispatch(lockCandidateAction(id, token));
        //     }
        // })
        // .catch((error) => {
        //     console.log(error);
        // })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }
}

export const updateCandidateAction = (id, token, initialState, avatar) => async dispatch => {

    try {

        let $reference = [{
            "last_name": initialState.referLastName1,
            "first_name": initialState.referFirstName1,
            "email": initialState.referEmail1,
            "telephone": initialState.referTelephone1,
            "company": initialState.referCompany1,
            "position": initialState.referPosition1,
            "date_start": initialState.referDepartureDate1
        }];

        if (initialState.referFirstName2.length > 0 || initialState.referLastName2.length > 0) {
            let $reference2 = {
                "last_name": initialState.referLastName2,
                "first_name": initialState.referFirstName2,
                "email": initialState.referEmail2,
                "telephone": initialState.referTelephone2,
                "company": initialState.referCompany2,
                "position": initialState.referPosition2,
                "date_start": initialState.referDepartureDate2
            }
            $reference.push($reference2);
        }

        const candidate = {
            "pro_id": id,
            "firstName": initialState.firstName,
            "lastName": initialState.lastName,
            "date_of_birth": initialState.dateOfBirth,
            "telephone": initialState.telephone,
            "lag_talk": {
                "en": initialState.en,
                "fr": initialState.fr,
                "es": 0,
                "po": 0,
                "ar": 0
            },
            "address": {
                "rue": initialState.street,
                "city": initialState.city,
                "province": initialState.province,
                "country": initialState.country,
                "postcode": initialState.postcode
            },
            "authorization_status": initialState.authorization_status,
            "criminal_status": 0,
            "experience_status": initialState.experience_status,
            "reference": $reference,
            "motivation": {
                "work_regularly": initialState.workRegurary,
                "work_extra": initialState.workExtra,
                "extra_income": initialState.extraIncome,
                "visibility": initialState.visibility,
                "concept": initialState.concept
            },
            "how_know_us_id": initialState.how_know_us,
            "smartphone_status": initialState.smartphone_status,
            "health_status": initialState.health_status,
            "health_description": initialState.healthDescription
        }

        const content = JSON.stringify(candidate);

        const headers = {
            headers: { 
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'text/plain'
            }
        }

        await axios.post(`${config.API_SERVER}/api/v1/candidate/update`, content, headers)
            .then((res) => {
                let candidate = res.data;
                candidate.avatar = avatar;
                dispatch({
                    type: CANDIDATE_SUCCESSFULLY_UPDATED,
                    payload: { pro: candidate, status: res.status }
                })
            })
            .then(() => {
                dispatch(setAlert("L'information a été enregistrées avec succès", 'success'));
            }).catch((error) => {
                console.log(error);
                // localStorage.clear();
                // dispatch(logOutTokenExpired());
            })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }
}

export const updateCandidatePhotoAction = (id, token, files) => async dispatch => {

    try {
        const archivos = files;
        const f = new FormData();
        for (let index = 0; index < archivos.length; index++) {
            f.append("avatar", archivos[index]);
        }

        f.append('id', id);

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        await axios.post(`${config.API_SERVER}/api/v1/candidate/avatar/update`, f, headers)
            .then((res) => {
                dispatch({
                    type: AVATAR_UPDATED_SUCCESSFULLY,
                    payload: { status: res.status }
                });
            })
            .then(() => {

                const data = {
                    id: id
                }
                axios.post(`${config.API_SERVER}/api/v1/candidate/avatar/valid`, data, headers)
                .then((res) => {

                    if(res.status === 200){
                        dispatch({
                            type: AVATAR_VALIDATED_SUCCESSFULLY,
                            payload: { status: res.status }
                        })
                        dispatch(setAlert("L'image a été mise à jour et validée avec succès", 'success'));
                    }
                
                }).catch((error) => {
                    dispatch({
                        type: FAILED_PROCESS,
                        error,
                    })
                })

            })
            .catch((error) => {
                console.log(error);
                // localStorage.clear();
                // dispatch(logOutTokenExpired());
            })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }
}

export const sendEmailRefuse = (id, headers) => async dispatch => {
    try {
                
        const data = {
            id: id
        }

        await axios.post(`${config.API_SERVER}/api/v1/candidate/eliminate`, data, headers)
        .then((res) => {
            
        })
    } catch (error) {
        console.log('Error: ', error)
    }
} 

export const updateCandidateStatusAction = (id, token, status_id, name, candidate = null, history, condition) => async dispatch => {
    try {
        
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id,
            status_id: status_id
        }

        await axios.post(`${config.API_SERVER}/api/v1/candidate/updateStatus`, data, headers)
            .then((res) => {

                if (res.status === 200) {
                   
                    if(status_id === '3' || status_id === '4') {
                        if(status_id === '4'){
                            dispatch(sendEmailRefuse(id, headers))
                        }
                
                        if (condition === 1){ 
                            history('/recruitment/candidatelist');
                        }
                    }else{
                        dispatch({
                            type: STATUS_UPDATED_SUCCESSFULLY,
                            payload: { pro: candidate, status: res.status, name: name }
                        });
                    }

                }
                
            }).catch((error) => {
                console.log(error);
                dispatch(setAlert(`Il y a un problème avec l'application`, 'error'));
            })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }

}

export const updateCandidateDateMailVisioAction = (id, token, timeInterviewMail, lag, firstName, lastName, email, telephone, candidate) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id,
            time: timeInterviewMail,
            lag: lag,
            //link: link
        }
        
        await axios.post(`${config.API_SERVER}/api/v1/candidate/interview/mail`, data, headers)
        .then((res) => {
            dispatch({
                type: DATE_INTERVIEW_MAIL_UPDATED_SUCCESSFULLY,
                payload: { pro: candidate, status: res.status, date: timeInterviewMail }
            });
            dispatch(setAlert(`La date ${timeInterviewMail} de Mail Visio a été mis à jour avec succès`, 'success'));
        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        })

    } catch (error) {

        dispatch({
            type: FAILED_PROCESS,
            error,
        })
        
    }

}

export const unlockCandidateAction = (id, token, firstName, lastName, history) => async dispatch => {
    try {
                
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id
        }

        await axios.post(`${config.API_SERVER}/api/v1/candidate/unlockCandidate`, data, headers)
        .then((res) => {                
            
            if(res.status === 200){
                localStorage.removeItem('candidateId');
            }
        })
        .then(() => {
            if(localStorage.hasOwnProperty('goToCandidateList')){
                localStorage.removeItem('goToCandidateList');
                // dispatch({
                //     type: PROCESSING,
                // });
                history('/recruitment/candidatelist');
            }
        }).catch((error) => {
            console.log(error);
        })
    } catch (error) {
        console.log(error);
    }

}

export const lockCandidateAction = (id, token) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id
        }

        await axios.post(`${config.API_SERVER}/api/v1/candidate/lockCandidate`, data, headers)
            .then((res) => {             
                
                if(res.status === 200){
                    localStorage.setItem('candidateId', id);
                }
            }).catch((error) => {
                console.log(error);
            })


    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }

}

//
export const updateCandidateVisioDateProgrammedAction = (email, dateTimeVisio) => async dispatch => {
    try {

        let reschedule = false;
        /*
            reschedule variable is created in Reschedule module. That means only Reschedule module('http://localhost:3001/reschedule/joniermh21%40hotmail.com') can updated the video conference date.
            
            If someone wants to change the video conference date directly from ('http://localhost:3001/calendar-form/joniermh21%40hotmail.com'), it will return a error and the user will redirec to a message to indicate that he has a date for the vidio conference.
        */
        if(localStorage.hasOwnProperty('reschedule')){
            reschedule = localStorage.getItem('reschedule');
        }
        const data = {
            email: email,
            time: dateTimeVisio,
            reschedule: reschedule
        }
        console.log('Schedule time: ', data)
        await axios.post(`${config.API_SERVER}/api/schedule/time`, data)
        .then((res) => {

            if (res.status === 200) {
                if(localStorage.hasOwnProperty('reschedule')){
                    localStorage.removeItem('reschedule');
                }
            }
        }).catch((error) => {
            dispatch(interviewDateExists());
            console.log(error);
        })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }
}

export const updateCandidateVisioStatusProgrammedAction = (id, token, candidate) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id
        }

        //await axios.post(`${config.API_SERVER}/api/v1/candidate/validateInterview`, data, headers)
        await axios.post(`${config.API_SERVER}/api/v1/candidate/interview/valid`, data, headers)
            .then((res) => {
                dispatch({
                    type: STATUS_INTERVIEW_UPDATED_SUCCESSFULLY,
                    payload: { pro: candidate, status: res.status }
                });
                dispatch(setAlert("L'entretien a été réalisé avec succès", 'success'));
            }).catch((error) => {
                console.log(error);
                // localStorage.clear();
                // dispatch(logOutTokenExpired());
            })


    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }

}

export const updateBgStatusCandidateAction = (id, token, statusId, name, candidate) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id
        }

        await axios.post(`${config.API_SERVER}/api/v1/candidate/validateBgStatus`, data, headers)
            .then((res) => {
                if(statusId === 1){
                    dispatch(updateCandidateStatusAction(id, token, 2, name, candidate));
                    dispatch(setAlert("Les antécédents juridiques est passé à Vérifié et l'état a été changé", 'success'));
                }else{
                    dispatch(setAlert("Les antécédents juridiques est passé à Vérifié", 'success'));
                }
                
            }).catch((error) => {
                console.log(error);
                // localStorage.clear();
                // dispatch(logOutTokenExpired());
            })


    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }

}


export const updateReferenceCandidateAction = (id, token, referStatus1, referStatus2, statusId, name, candidate) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id,
            contact_first: referStatus1,
            contact_second: referStatus2
        }
        await axios.post(`${config.API_SERVER}/api/v1/candidate/validateContact`, data, headers)
            .then((res) => {
                if (res.status === 200) {
                    if(statusId === 1){
                        dispatch(updateCandidateStatusAction(id, token, 2, name, candidate));
                        dispatch(setAlert("Les références ont été validées et l'état a été changé", 'success'));
                    }else{
                        dispatch(setAlert("Les références ont été validées", 'success'));
                    }

                } else {
                    dispatch(setAlert("Problemas Con el Servidor", 'error'));
                }
            })
            .catch((error) => {
                console.log(error);
                // localStorage.clear();
                // dispatch(logOutTokenExpired());
            })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }

}

export const updateCandidateAvatarRejectsAction = (id, token, lag, candidate) => async dispatch => {

    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id,
            lag: lag
        }

        await axios.post(`${config.API_SERVER}/api/v1/candidate/avatar/refuse`, data, headers)
            .then((res) => {
                dispatch({
                    type: AVATAR_STATUS_UPDATED_SUCCESSFULLY,
                    payload: { pro: candidate, status: res.status }
                });
                dispatch(setAlert("La photo a été rejetée", 'success'));
            }).catch((error) => {
                console.log(error);
                // localStorage.clear();
                // dispatch(logOutTokenExpired());
            })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }
}

export const getMessage = (token, id) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        await axios.get(`${config.API_SERVER}/api/v1/candidate/dialogue/${id}`, headers)
            .then((res) => {
                dispatch({
                    type: GET_MESSAGES,
                    payload: { messages: res.data.dialogue }
                })
            }).catch((error) => {
                console.log(error);
                // localStorage.clear();
                // dispatch(logOutTokenExpired());
            })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }
}


export const addMessage = (token, id, content, messagesc) => async dispatch => {
    try {
      
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }
        const data = {
            candidate_id: id,
            content: content
        }
        await axios.post(`${config.API_SERVER}/api/v1/candidate/dialogue/create`, data, headers)
            .then((res) => {
                dispatch({
                    type: ADD_MESSAGES,
                    payload: { messages: [...messagesc, res.data.dialogue] }
                })
                //dispatch(setAlert("Mensaje Creado", 'success'));
            })
            .catch((error) => {
                console.log(error);
                // localStorage.clear();
                // dispatch(logOutTokenExpired());
            })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })
    }
}

export const getNotationsCandidateAction = (id, token) => async dispatch => {
    try {
        
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        axios.get(`${config.API_SERVER}/api/v1/candidate/notations/${id}`, headers)
            .then(res => {

                const arrayM = res.data.notations;

                let arrayNo = [];
                for (let index in arrayM) {
                    arrayNo[arrayM[index].id] = arrayM[index];
                }

                dispatch({
                    type: GET_NOTATIONS_CANDIDATE,
                    payload: { notations: arrayNo }
                })
            }).catch((error) => {
                console.log(error)
            })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })

    }
}


export const getMessagesCandidateAction = (id, token) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        axios.get(`${config.API_SERVER}/api/v1/candidate/dialogue/${id}`, headers)
            .then(res => {
                const arrayM = res.data.dialogue;
                let arrayNo = [];
                for (let index in arrayM) {
                    arrayNo[arrayM[index].id] = arrayM[index];
                }
                dispatch({
                    type: GET_MESSAGES_CANDIDATE,
                    payload: { messages: arrayNo }
                })
            }).catch((error) => {
                console.log(error)
            })

    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })

    }
}

export const cancelVideo = (email) => async dispatch => {
    try {
        const data = {
            email: email
        }

        await axios.post(`${config.API_SERVER}/api/schedule/cancel`, data)
            .then((res) => {
                dispatch({
                    type: CANCEL_VISIO,
                    payload: { status: res.status }
                })
            })
            .catch((error) => {
                dispatch({
                    type: FAILED_PROCESS,
                    error,
                })
            })        
    } catch (error) {
        dispatch({
            type: FAILED_PROCESS,
            error,
        })

    }
}