import React from 'react';
import { Checkbox, FormControl, FormControlLabel, RadioGroup, Radio, Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SelectInput from '../../share/inputs/SelectInput';
import { makeStyles } from '@material-ui/core/styles';
import Input from '../../share/inputs/Input';
import InputCustomPhone from '../../share/inputs/InputCustomPhone';
import { useState, useEffect, useRef } from 'react';

const useStyles = makeStyles((theme) => ({
    groupPerInfo: {
        height: '6.5rem',
        marginTop: '5px',
        '& .MuiFormControl-root': {
            marginRight: '1.5rem',
        },
        '& input': {
            marginTop: '1rem'
        },
        '& select': {
            marginTop: '1rem'
        }
    },
    groupInfo: {
        height: '7rem',
        marginTop: '1.5rem',
        '& .MuiFormControl-root': {
            marginRight: '1.5rem',
        },
        '& input': {
            marginTop: '1rem'
        },
        '& select': {
            marginTop: '1rem'
        }
    },
    groupInfoSecondArea: {
        height: '7.5rem',
        marginTop: '1.5rem',
        '& .MuiFormControl-root': {
            marginRight: '1.5rem',
        },
        '& input': {
            marginTop: '1rem'
        },
        '& select': {
            marginTop: '1rem'
        }
    },
    groupInfoAddress: {
        height: '5rem',
        marginTop: '5px',
        '& .MuiFormControl-root': {
            marginRight: '1.5rem',
        },
        '& input': {
            marginTop: '1rem'
        },
        '& select': {
            marginTop: '1rem'
        }
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    groupCheckBoxes: {
        display: "flex",
        flexWrap: "wrap"
    },
    classCheckBox: {
        '& .MuiSvgIcon-root': {
            fontSize: '3rem'
        },
        '& .MuiTypography-root': {
            fontSize: '2rem'
        }
    },
    noTextTransform: {
        '& .MuiButtonBase-root': {
            textTransform: "none",
            fontSize: '1.6rem',
            backgroundColor: '#e0e0e0',
            color: '#000'
        }
    },
    errorMessage: {
        color: '#dc3545',
        fontSize: '1.6rem',
        marginTop: '-8px'
    },
    img: {
        '& img':{
            width: '16rem',
            height: '17.7rem'
        }
    },
    btnDate: {
        textTransform: 'none',
        fontSize: '14px',
        '@media (max-width:1500px)': {
            fontSize: '13px',
        },
        '@media (max-width:1410px)': {
            fontSize: '10px',
            padding: '6px 8px',
        },
    },
    btn: {
        border:0,
        outline: 0,
        "&:focus": {
            outline: "none"
          }
    }
}));

function CandidateInfo(props) {
    const classes = useStyles();
    const { initialState, CandidateChecked, handleChange, updateMailVisio, openPopup, setImages,
        savePhoto, validateCandidate, validateMailVisio, avatarRejects, imgChanged } = props;
    const [clicked, setClicked] = useState(0)
    const [count, setCount] = useState(0)
    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00:00');
       useEffect(() => {
		clearTimer(getDeadTime());
    }, []); 
	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total, hours, minutes, seconds
		};
	}


	const startTimer = (e) => {
		let { total, hours, minutes, seconds }
					= getTimeRemaining(e);
		if (total >= 0) {

			setTimer(
				(hours > 9 ? hours : '0' + hours) + ':' +
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)
		}
	}


	const clearTimer = (e) => {


		setTimer('00:01:00');

		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const getDeadTime = () => {
		let deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + 60);
		return deadline;
    }    
    const disableFunction = () => {
     
        if (clicked===0 && initialState.emailInterviewStatus === 1 && count >= 1 && initialState.interviewStatus === 0 &&  timer === '00:00:00' ){
            return false
        } else if (clicked===0 && initialState.emailInterviewStatus === 1 && count === 0 && initialState.interviewStatus !== 1 ){
            return false
        } else if (clicked===1 && initialState.emailInterviewStatus === 1 && count === 1) 
       {
        return false
       }
       else if (clicked===1 && count >1 && timer === '00:00:00'){
            return false
        }
        else return true
       
       
    }
     
     
    return (
        <div style={{padding: '0'}}>
            <Grid container spacing={1}>
                <Grid item sm={12} md={5} lg={5} xl={4}>
                    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-around">
                        <Box display="flex" flexDirection="row" mt={2}>
                            <Box mr={2} className={classes.img}>
                                {(initialState.avatarStatus === 1) ?
                                        (<img src="images/default_man.png" alt="" />)
                                    :
                                        (<img src={initialState.preview.length === 0 ? initialState.avatarUrl : initialState.preview} alt="" />)
                                }

                            </Box>
                            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
                                {/* <Button href="#" color="primary" className={classes.noTextTransform}>Valider la photo</Button> */}
                                {(initialState.avatarStatus === 0) && (
                                    <Button href="#" onClick={(e) => avatarRejects(e)} color="primary" style={{ color: 'red' }} className={classes.noTextTransform}>Refuser la photo</Button>
                                )}

                                {(initialState.avatarStatus > 0) && (
                                    <Box ml={1} className={classes.noTextTransform}>
                                        {(imgChanged) ?
                                            (<Button htmlFor="sampleFile" component="label" variant="contained" type="submit">Choisir un fichier</Button>)
                                            :
                                            (<Button variant="contained" onClick={(e) => savePhoto(e)}>Sauvegarder le fichier</Button>)
                                        }
                                        <input id="sampleFile" type="file" name="files" style={{ display: 'none' }} multiple onChange={setImages} />
                                    </Box>
                                )}

                            </Box>
                        </Box>
                        <Box>
                            <label style={{fontSize: '1.6rem', color: '#7f7f7f'}}>
                                Description Todoo
                            </label>
                            <Box style={{color: '#3ad640'}}>
                                {initialState.self_description}
                            </Box>
                        </Box>
    
                        <Box className={classes.groupPerInfo}>
                            <Box display="flex">
                                <Input
                                    id="firstName"
                                    placeholder=""
                                    label="Prénom"
                                    size="small"
                                    defaultValue={initialState.firstName}
                                    onBlur={e => handleChange(e)}
                                    width="100%"
                                    error={initialState.firstName.length === 0 ? 'Le prénom est requis' : ''}
                                    readOnly={true}

                                />
                                <Input
                                    id="lastName"
                                    placeholder=""
                                    label="Nom"
                                    size="small"
                                    defaultValue={initialState.lastName}
                                    onBlur={e => handleChange(e)}
                                    width="100%"
                                    error={initialState.lastName.length === 0 ? 'Le nom est requis' : ''}
                                    readOnly={true}
                                />
                            </Box>
                        </Box>

                        <Box className={classes.groupPerInfo}>
                            <Input
                                id="email"
                                placeholder=""
                                label="Courriel"
                                size="small"
                                defaultValue={initialState.email}
                                type="email"
                                //onBlur={e => handleChange(e)}
                                width="100%"
                                error={initialState.email.length === 0 ? 'Le courriel est requis' : ''}
                                readOnly={true}

                            />
                        </Box>
                        
                        <Box className={classes.groupPerInfo}>
                                <InputCustomPhone
                                    name = 'telephone'
                                    country={'ca'}
                                    label="Cellulaire"
                                    value={initialState.telephone}              
                                    disableCountryCode={false}
                                    disableAreaCodes={true}
                                    disableDropdown={true}
                                    onChange={e => handleChange(e)}
                                    variant="filled"
                                    size="small"
                                    width= {'100%'}                                                      
                                    error=''
                                    readOnly={true}
                                />                             
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Box display="flex" flexDirection="column" justifyContent="space-around">
                        <Box className={classes.groupInfoAddress} mb={2}>
                            <Box style={{ color: '#7f7f7f', fontSize: '16px' }}>Adress</Box>
                            <Box display="flex">
                                <Box style={{ fontSize: '16px' }}>{`${initialState.street}, ${initialState.city}, ${initialState.province}, ${initialState.country}, ${initialState.postcode}`}</Box>
                            </Box>

                        </Box>
                        <Box className={classes.groupInfo}>
                            <Input
                                id="dateOfBirth"
                                placeholder=""
                                label="Date de naissance"
                                size="small"
                                type="date"
                                defaultValue={initialState.dateOfBirth}
                                onBlur={e => handleChange(e)}
                                width="200px"
                                error={initialState.dateOfBirth.length === 0 ? 'Le date de naissance est requis' : ''}
                                readOnly={true}
                            />
                        </Box>
                        <Box className={classes.groupInfoSecondArea}>
                            <Box><Typography variant="h5">Autorisation de travail</Typography></Box>
                            {/* onChange={e => handleChange(e)} */}
                            <RadioGroup row aria-label="gender" name="authorization_status"
                                value={(initialState.authorization_status === 1) ? "1" : "0"} >
                                <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                                <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                            </RadioGroup>
                        </Box>

                        <Box className={classes.groupInfoSecondArea}>
                            <Box><Typography variant="h5">As-tu un smartphone ?</Typography></Box>
                            {/* onChange={e => handleChange(e)} */}
                            <RadioGroup row aria-label="gender" name="smartphone_status"
                                value={(initialState.smartphone_status === 1) ? "1" : "0"}

                            >
                                <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                                <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                            </RadioGroup>
                        </Box>

                        <Box className={classes.groupInfoSecondArea}>
                            <Box><Typography variant="h5">Languages</Typography></Box>
                            <Box display="flex" flexDirection="row">
                                {/* onClick={e => handleChange(e)} */}
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="fr" color="primary" checked={initialState.fr ? true : false} />}
                                    label="fr"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="en" color="primary" checked={initialState.en ? true : false} />}
                                    label="en"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="es" color="primary" checked={initialState.es ? true : false} />}
                                    label="es"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="ar" color="primary" checked={initialState.ar ? true : false} />}
                                    label="ar"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="po" color="primary" checked={initialState.po ? true : false} />}
                                    label="po"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="zh" color="primary" checked={initialState.zh ? true : false} />}
                                    label="ma"
                                />
                            </Box>
                            {(!initialState.fr && !initialState.en) && (
                                <Box className={classes.errorMessage}>Choisir au moins une langue</Box>
                            )}
                        </Box>
                    </Box>
                </Grid>

                <Grid item sm={12} md={3} lg={3} xl={4}>

                    <Box className={classes.groupInfoSecondArea}>
                        <Box><Typography variant="h5">Antécédents juridiques</Typography></Box>
                        {/* disabled={initialState.backgroundCheckStatus ? true : false} */}
                        <FormControl disabled={initialState.backgroundCheckStatus === 1 ? true : false}>
                            <SelectInput
                                id="backgroundCheckStatus"
                                name="backgroundCheckStatus"
                                data={CandidateChecked}
                                colorFont={{ color: '#25c96b', fontWeight: 'bold' }}
                                onChange={(e) => openPopup(e)}
                                defaultValue={initialState.backgroundCheckStatus}
                                disabled={initialState.backgroundCheckStatus === 1 ? true : false}
                                error={(validateCandidate && initialState.backgroundCheckStatus === 0) ? 'Requi' : ''}
                            />
                        </FormControl>
                    </Box>

                    <Box className={classes.groupInfoSecondArea}>
                        <Box display="flex" flexDirection="row" alignItems="flex-end">
                            <Box display="flex" flexDirection="column">
                                <Box><Typography variant="h5">Mail Visio</Typography></Box>
                                <Input
                                    id="timeInterviewMail"
                                    placeholder=""
                                    label=""
                                    size="small"
                                    //type="date"
                                    //defaultValue={initialState.timeInterviewMail.length > 0 ? initialState.timeInterviewMail : getFechaEmailSent}
                                    defaultValue={initialState.timeInterviewMail}
                                    onBlur={e => handleChange(e)}
                                    width="145px"
                                    error={validateMailVisio ? "Requi" : ""}
                                    readOnly={true}
                                />
                            </Box>
                            <Box>
                                <Button
                                    className={classes.btn}
                                    color="primary"
                                    variant="contained"
                                    onClick={(e) => {  setClicked(clicked + 1);setCount(count +1 ); updateMailVisio(e);}}
                                    disabled={initialState.emailInterviewStatus ? true : false}
                                >
                                    Envoyer
                                </Button>

                            </Box>
                            
                            <Box marginLeft="10px"  marginRight={"10px"}>
                            <Button
                             
                                    color="primary"
                                    variant="contained"
                                    className={classes.btn}
                                    //className={classes.btnDate}
                                    onClick={(e) => {  setCount(count + 1); clearTimer(getDeadTime()); updateMailVisio(e);}}
                                    disabled={disableFunction() }
                                >
                                    Renvoyer
                                </Button>
                                
                            </Box>
                            <p hidden={(clicked === 1 && initialState.emailInterviewStatus > 0 && count < 2) || 
                                (initialState.emailInterviewStatus > 0 && count === 0 && clicked === 0 )|| 
                               ( initialState.emailInterviewStatus === 0 && count === 0 && clicked === 0) }>{timer}</p>
                        </Box>
                    </Box>
                    
                    <Box className={classes.groupInfoSecondArea}>
                        <Box display="flex" flexDirection="row" alignItems="flex-end">
                            <Box>
                                <Box><Typography variant="h5">Visio programmé</Typography></Box>
                                <Input
                                    id="timeInterview"
                                    placeholder=""
                                    label=""
                                    size="small"
                                    //type="datetime-local" //datetime-local
                                    defaultValue={initialState.timeInterview}
                                    onBlur={e => handleChange(e)}
                                    width="145px"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.groupInfoSecondArea}>
                            <Box display="flex" flexDirection="column">
                                <Box><Typography variant="h5">Visio effectuée</Typography></Box>
                                <FormControlLabel
                                    disabled={initialState.interviewStatus === 1 ? true : false}
                                    control={
                                        <Checkbox
                                            className={classes.classCheckBox}
                                            name="interviewStatus"
                                            checked={initialState.interviewStatus === 1 ? true : false}
                                            color="primary"
                                            onClick={(e) => openPopup(e)}
                                        />
                                    }
                                />
                             
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

export default CandidateInfo;